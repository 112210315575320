import * as CONSTANTS from './constants'
import { apiGet } from "@common/crud";

export function getSiteDetail(siteId) {
    return dispatch => {
        dispatch(detailSiteActionLoading())
        dispatch(apiGet('sites/' + siteId, {}, {}, detailSiteAction))
    };
}

function detailSiteAction(response) {
    return {
        type: CONSTANTS.SITE_DETAIL,
        payload: response
    };
}

function detailSiteActionLoading() {
    return {
        type: CONSTANTS.SITE_DETAIL_LOADING,
        payload: null
    };
}