import React, { Component } from 'react';
import { ToolboxControl, AntCard, DataEmpty } from "@layouts";
import { connect } from "react-redux";
import { DesktopOutlined } from "@ant-design/icons";

class CustomComponent extends Component {
    render() {
        const { common } = this.props;
        const parentElementId = "site-event1";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';
        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <DesktopOutlined/> Event
                            <i> - Bel Vietnam</i>
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                        />
                    </div>
                }
            >
                <div className="card-custom-body">
                    <DataEmpty title="No current event."/>
                </div>
            </AntCard>
        )
    }
}

function mapStateToProps(state) {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);