import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: "center",
        height: 15,
        flexGrow: 1,
    },

    timeType: {
        width: "28%",
        textAlign: "left",
    },
    price: {
        width: "25%",
        textAlign: 'right',
    },
    total: {
        width: "23%",
        textAlign: "right",
    },
    cash: {
        width: '24%',
        textAlign: 'right',
    },
});

const TableRow = ({ items }) => {
    const rows = !items ? null : items.map((item, idx) =>
        <View style={styles.row} key={idx}>
            <Text style={styles.timeType}>{item.name}</Text>
            <Text style={styles.price}>{item.unitPrice}</Text>
            <Text style={styles.total}>{item.consumption}</Text>
            <Text style={styles.cash}>{item.price}</Text>
        </View>
    )
    return (<Fragment>{rows}</Fragment>)
};

export default TableRow