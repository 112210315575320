// Initial state is the place you define all initial values for the Redux store of the feature.
const initialState = {
    data: [],
    code: null,
    message: '',
    errors: [],
    loading: false,
    status: null,
    statusText: '',
};

export default initialState;
