import * as CONSTANTS from "./constants";
import { apiGet } from "@common/crud";

/**
 * Get billing scheme
 * @param plantId
 * @param schemeId
 * @returns
 */
export function getBillingScheme(plantId, schemeId = null) {
    schemeId = schemeId ?? "";
    return dispatch => {
        dispatch(getBillingSchemeLoadingAction())
        dispatch(apiGet(`billing/${plantId}/billing-scheme/${schemeId}`, {}, {}, getBillingSchemeAction))
    };
}

function getBillingSchemeAction(response) {
    return {
        type: CONSTANTS.GET_BILLING_SCHEME,
        payload: response.data
    };
}

function getBillingSchemeLoadingAction() {
    return {
        type: CONSTANTS.GET_BILLING_SCHEME_LOADING,
        payload: null
    };
}