import Highcharts from "highcharts";
import helpers from "@ultis/helpers";
import {
    DATA_TYPE, FILTER_LAST_12_MONTHS,
    FILTER_LAST_30_DAYS, FILTER_LAST_6_MONTHS,
    FILTER_LAST_7_DAYS, FILTER_THIS_MONTH,
    FILTER_THIS_WEEK, FILTER_THIS_YEAR,
    FILTER_TODAY,
    FILTER_YESTERDAY
} from "@ultis/constants";

/**
 * %a: Short weekday, like 'Mon'.
 * %A: Long weekday, like 'Monday'.
 * %d: Two digits day of the month, 01 to 31.
 * %e: Day of the month, 1 through 31.
 * %b: Short month, like 'Jan'.
 * %B: Long month, like 'January'.
 * %m: Two digit month number, 01 through 12.
 * %y: Two digits year, like 09 for 2009.
 * %Y: Four digits year, like 2009.
 * %H: Two digits hours in 24h format, 00 through 23.
 * %I: Two digits hours in 12h format, 00 through 11.
 * %l (Lower case L): Hours in 12h format, 1 through 11.
 * %M: Two digits minutes, 00 through 59.
 * %p: Upper case AM or PM.
 * %P: Lower case AM or PM.
 * %S: Two digits seconds, 00 through 59
 */
export const TIMEZONE = 'GMT+7'

/**
 * Render label
 * @param config
 * @returns {{formatter: (function(): string)}}
 */
export function renderLabel(config) {
    let configs = {
        "month": "%b '%y",
    }

    return configs[config] ? {
        formatter: function () {
            return Highcharts.dateFormat(configs[config], this.value);
        }
    } : {}
}

export function renderSeriesDailyIODiagram(series) {
    let seriesRender = [];
    let tooltip = [];
    series.forEach((item) => {
        seriesRender.push({
            "marker": {
                "enabled": true
            },
            "states": {
                "hover": {
                    "enabled": true,
                }
            },
            "type": "scatter",
            "name": item.name ?? "",
            "data": item.data ?? [],
        });
        tooltip.push(item.tooltip)
    });

    return {
        chartSeries: series,
        chartTooltip: tooltip
    };
}

/**
 * Render tooltip date format
 * @param config
 * @param timezone
 * @returns {string|*}
 */
export function tooltipDateFormat(config, timezone) {
    let configs = {
        "hour": "%Y/%m/%d %H:%M:%S " + timezone,
        "date": "%Y/%m/%d " + timezone,
        "month": "%B %Y " + timezone,
    }

    return configs[config] === undefined ? '' : configs[config]
}

/**
 * Render tooltip date format
 * @param config
 * @returns {string|*}
 */
export function columnDateFormat(config) {
    let configs = {
        "hour": "DD/MM/YYYY HH:mm:ss",
        "date": "DD/MM/YYYY",
        "month": "MMM YYYY",
    }

    return configs[config] === undefined ? '' : configs[config]
}

export function renderDataTable(series, groupBy) {
    let data = []
    let countSeries = series.length;
    let dataSeries = [];
    let unitSeries = [];
    let totalRows = 0;
    let formatTime = columnDateFormat(groupBy)

    // Scan list of series
    for (const item of series) {
        dataSeries.push(item.data)
        unitSeries.push(item.unit)

        // Render total rows
        totalRows = item.data ? item.data.length : 0
    }

    // Render data
    for (let i = 0; i < totalRows; i++) {
        let tempData = {
            "key": i,
        }
        for (let j = 0; j < countSeries; j++) {
            let rowData = dataSeries[j][i];
            tempData["time"] = helpers.timestampFormat(rowData[0], formatTime);
            tempData[j] = rowData[1];
        }

        data.push(tempData)
    }

    return {
        unitSeries,
        data,
    };
}

/**
 * Render chart config by type
 * @param dataType
 * @returns {{ranges: {}, storeField: string, title: string}}
 */
export function getChartConfigByType(dataType) {
    let title = ""
    let storeField = ""
    let ranges = {}

    const configDateRange = {
        FILTER_TODAY: helpers.getTimeRangeByFilterTime(FILTER_TODAY),
        FILTER_YESTERDAY: helpers.getTimeRangeByFilterTime(FILTER_YESTERDAY),
        FILTER_LAST_7_DAYS: helpers.getTimeRangeByFilterTime(FILTER_LAST_7_DAYS),
        FILTER_THIS_WEEK: helpers.getTimeRangeByFilterTime(FILTER_THIS_WEEK),
        FILTER_LAST_30_DAYS: helpers.getTimeRangeByFilterTime(FILTER_LAST_30_DAYS),
        FILTER_THIS_MONTH: helpers.getTimeRangeByFilterTime(FILTER_THIS_MONTH),
        FILTER_LAST_6_MONTHS: helpers.getTimeRangeByFilterTime(FILTER_LAST_6_MONTHS),
        FILTER_LAST_12_MONTHS: helpers.getTimeRangeByFilterTime(FILTER_LAST_12_MONTHS),
        FILTER_THIS_YEAR: helpers.getTimeRangeByFilterTime(FILTER_THIS_YEAR),
    }

    switch (dataType) {
        case DATA_TYPE.ACTUAL_PRODUCTION_AND_IRRADIATION:
            title = "Actual Production & Actual Irradiation"
            storeField = "actualProductionIrradiation"
            ranges = {
                'Today': [configDateRange['FILTER_TODAY'].from, configDateRange['FILTER_TODAY'].to],
                'Yesterday': [configDateRange['FILTER_YESTERDAY'].from, configDateRange['FILTER_YESTERDAY'].to],
                'Last 7 day': [configDateRange['FILTER_LAST_7_DAYS'].from, configDateRange['FILTER_LAST_7_DAYS'].to],
                'This week': [configDateRange['FILTER_THIS_WEEK'].from, configDateRange['FILTER_THIS_WEEK'].to],
                'Last 30 days': [configDateRange['FILTER_LAST_30_DAYS'].from, configDateRange['FILTER_LAST_30_DAYS'].to],
                'This month': [configDateRange['FILTER_THIS_MONTH'].from, configDateRange['FILTER_THIS_MONTH'].to],
                'Last 6 months': [configDateRange['FILTER_LAST_6_MONTHS'].from, configDateRange['FILTER_LAST_6_MONTHS'].to],
                'Last 12 months': [configDateRange['FILTER_LAST_12_MONTHS'].from, configDateRange['FILTER_LAST_12_MONTHS'].to],
                'This year': [configDateRange['FILTER_THIS_YEAR'].from, configDateRange['FILTER_THIS_YEAR'].to],
            }
            break;
        case DATA_TYPE.STRING_MONITORING:
            title = "String Monitoring"
            storeField = "stringMonitoring"
            break;
        case DATA_TYPE.IV_CURVE:
            title = "I-V Curve"
            storeField = "IVCurve"
            break;
        case DATA_TYPE.SPECIFIC_YIELD:
            title = "Specific Yield"
            storeField = "specificYield"
            ranges = {
                'Last 7 day': [configDateRange['FILTER_LAST_7_DAYS'].from, configDateRange['FILTER_LAST_7_DAYS'].to],
                'This week': [configDateRange['FILTER_THIS_WEEK'].from, configDateRange['FILTER_THIS_WEEK'].to],
                'Last 30 days': [configDateRange['FILTER_LAST_30_DAYS'].from, configDateRange['FILTER_LAST_30_DAYS'].to],
                'This month': [configDateRange['FILTER_THIS_MONTH'].from, configDateRange['FILTER_THIS_MONTH'].to],
                'Last 6 months': [configDateRange['FILTER_LAST_6_MONTHS'].from, configDateRange['FILTER_LAST_6_MONTHS'].to],
                'Last 12 months': [configDateRange['FILTER_LAST_12_MONTHS'].from, configDateRange['FILTER_LAST_12_MONTHS'].to],
                'This year': [configDateRange['FILTER_THIS_YEAR'].from, configDateRange['FILTER_THIS_YEAR'].to],
            }
            break;
        case DATA_TYPE.ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION:
            title = "Actual Production vs Budget Production"
            storeField = "actualProductionVsBudgetProduction"
            ranges = {
                'Last 7 day': [configDateRange['FILTER_LAST_7_DAYS'].from, configDateRange['FILTER_LAST_7_DAYS'].to],
                'This week': [configDateRange['FILTER_THIS_WEEK'].from, configDateRange['FILTER_THIS_WEEK'].to],
                'Last 30 days': [configDateRange['FILTER_LAST_30_DAYS'].from, configDateRange['FILTER_LAST_30_DAYS'].to],
                'This month': [configDateRange['FILTER_THIS_MONTH'].from, configDateRange['FILTER_THIS_MONTH'].to],
                'Last 6 months': [configDateRange['FILTER_LAST_6_MONTHS'].from, configDateRange['FILTER_LAST_6_MONTHS'].to],
                'Last 12 months': [configDateRange['FILTER_LAST_12_MONTHS'].from, configDateRange['FILTER_LAST_12_MONTHS'].to],
                'This year': [configDateRange['FILTER_THIS_YEAR'].from, configDateRange['FILTER_THIS_YEAR'].to],
            }
            break;
        case DATA_TYPE.CARBON_OFFSET:
            title = "Carbon offset"
            storeField = "carbonOffset"
            ranges = {
                'Last 7 day': [configDateRange['FILTER_LAST_7_DAYS'].from, configDateRange['FILTER_LAST_7_DAYS'].to],
                'This week': [configDateRange['FILTER_THIS_WEEK'].from, configDateRange['FILTER_THIS_WEEK'].to],
                'Last 30 days': [configDateRange['FILTER_LAST_30_DAYS'].from, configDateRange['FILTER_LAST_30_DAYS'].to],
                'This month': [configDateRange['FILTER_THIS_MONTH'].from, configDateRange['FILTER_THIS_MONTH'].to],
                'Last 6 months': [configDateRange['FILTER_LAST_6_MONTHS'].from, configDateRange['FILTER_LAST_6_MONTHS'].to],
                'Last 12 months': [configDateRange['FILTER_LAST_12_MONTHS'].from, configDateRange['FILTER_LAST_12_MONTHS'].to],
                'This year': [configDateRange['FILTER_THIS_YEAR'].from, configDateRange['FILTER_THIS_YEAR'].to],
            }
            break;
        case DATA_TYPE.PERFORMANCE_RATIO_TRACKING:
            title = "Performance Ratio Tracking"
            storeField = "performanceRatioTracking"
            ranges = {
                'Last 7 day': [configDateRange['FILTER_LAST_7_DAYS'].from, configDateRange['FILTER_LAST_7_DAYS'].to],
                'This week': [configDateRange['FILTER_THIS_WEEK'].from, configDateRange['FILTER_THIS_WEEK'].to],
                'Last 30 days': [configDateRange['FILTER_LAST_30_DAYS'].from, configDateRange['FILTER_LAST_30_DAYS'].to],
                'This month': [configDateRange['FILTER_THIS_MONTH'].from, configDateRange['FILTER_THIS_MONTH'].to],
                'Last 6 months': [configDateRange['FILTER_LAST_6_MONTHS'].from, configDateRange['FILTER_LAST_6_MONTHS'].to],
                'Last 12 months': [configDateRange['FILTER_LAST_12_MONTHS'].from, configDateRange['FILTER_LAST_12_MONTHS'].to],
                'This year': [configDateRange['FILTER_THIS_YEAR'].from, configDateRange['FILTER_THIS_YEAR'].to],
            }
            break;
        case DATA_TYPE.DAILY_IO_DIAGRAM:
            title = "Daily IO Diagram"
            storeField = "dailyIODiagram"
            ranges = {
                'Last 30 days': [configDateRange['FILTER_LAST_30_DAYS'].from, configDateRange['FILTER_LAST_30_DAYS'].to],
                'Last 12 months': [configDateRange['FILTER_LAST_12_MONTHS'].from, configDateRange['FILTER_LAST_12_MONTHS'].to],
                'This year': [configDateRange['FILTER_THIS_YEAR'].from, configDateRange['FILTER_THIS_YEAR'].to],
            }
            break;
        default:
            break
    }

    return {
        "title": title,
        "storeField": storeField,
        "ranges": ranges,
    }
}


export * from './ChartActualPrBudgetPr'
export * from './ChartActualProductionIrradiation'
export * from './ChartSpecificYield'
export * from './ChartActualProductionVsBudgetProduction'
export * from './ChartCarbonOffset'
export * from './ChartPerformanceRatioTracking'
export * from './ChartDailyIODiagram'
export * from './ChartCostTrend'
export * from './ChartStringMonitoring'
export * from './ChartIVCurve'

export * from './TableActualProductionIrradiation'
export * from './TableActualProductionVsBudgetProduction'
export * from './TableCarbonOffset'
export * from './TableDailyIODiagram'
export * from './TablePerformanceRatioTracking'
export * from './TableSpecificYield'
