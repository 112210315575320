import React, { Component } from 'react';
import { Skeleton } from "antd";
import * as Components from "../../components";

class President extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            onClickDetail,
            detail,
        } = this.props

        // Render data
        const { data, loading } = detail

        return (
            <div className="features feature-billing-configs">
                <h1 className="page-title" id="test">
                    {loading ? <Skeleton.Input active={loading}/> : data.name}
                </h1>
                <div>
                    <Components.BillingScheme onClickDetail={onClickDetail}/>
                </div>
            </div>

        )
    }
}

export default President;