import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        fontWeight: "bold",
        alignItems: "center",
        height: 18,
    },

    rowBgColor: {
        backgroundColor: "aliceblue",
    },
    rowColor: {
        color: "red",
    },
    prefix: {
        width: "70%",
        textAlign: "left",
    },
    prefixPowerUsed: {
        width: "50%",
        textAlign: "left",
    },
    suffixPowerUsed: {
        width: "25%",
    },
    powerUsedSum: {
        width: "25%",
        textAlign: "right",
    },
    totalPrice: {
        width: "30%",
        textAlign: "right",
    },
    tax: {
        width: "30%",
        textAlign: "right",
    },
    taxPrice: {
        width: "30%",
        textAlign: "right",
    },
    totalPriceInTax: {
        width: "30%",
        textAlign: "right",
    },
    totalPriceInTaxString: {
        textAlign: "right",
        fontWeight: "normal",
        fontStyle: "italic"
    },
});

const TableFooter = ({ data }) => {
    data = data ?? {}
    const {
        discountPrice,
        discountRatio,
        totalConsumption,
        taxRatio,
        taxPrice,
        totalPriceByConsumption,
        totalPriceNotInTax,
        totalPrice,
        totalPriceByText,
    } = data
    return (
        <View>
            <View style={[styles.row, styles.rowBgColor]}>
                <Text style={styles.prefixPowerUsed}>Tổng điện năng tiêu thụ (kWh)</Text>
                <Text style={styles.powerUsedSum}>{totalConsumption ?? 0}</Text>
                <Text style={styles.suffixPowerUsed}></Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.prefix}>Tổng tiền điện chưa thuế (đồng)</Text>
                <Text style={styles.totalPrice}>{totalPriceByConsumption ?? 0}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.prefix}>Giảm trừ chiết khấu {discountRatio} (đồng)</Text>
                <Text style={styles.tax}>{discountPrice}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.prefix}>Giá trị thanh toán chưa bao gồm thuế VAT (đồng)</Text>
                <Text style={styles.tax}>{totalPriceNotInTax ?? 0}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.prefix}>Thuế GTGT {taxRatio ?? 0} (đồng)</Text>
                <Text style={styles.taxPrice}>{taxPrice ?? 0}</Text>
            </View>
            <View style={[styles.row, styles.rowBgColor, styles.rowColor]}>
                <Text style={styles.prefix}>Tổng cộng tiền thanh toán (đồng)</Text>
                <Text style={styles.totalPriceInTax}>{totalPrice ?? 0}</Text>
            </View>
            <View style={styles.row}>
                <Text style={styles.totalPriceInTaxString}>{totalPriceByText ?? ""}</Text>
            </View>
        </View>
    )
};

export default TableFooter