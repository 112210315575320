import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: "center",
        height: 15,
        flexGrow: 1,
    },

    timeType: {
        width: "25%",
        textAlign: "left",
    },
    multiplication: {
        width: "15%",
        textAlign: 'right',
    },
    newValue: {
        width: "15%",
        textAlign: "right",
    },
    oldValue: {
        width: '15%',
        textAlign: 'right',
    },
    used: {
        width: '30%',
        textAlign: 'right',
    },
});

const TableRow = ({ items }) => {
    const rows = !items ? null : items.map((item, idx) =>
        <View style={styles.row} key={idx}>
            <Text style={styles.timeType}>{item.name}</Text>
            <Text style={styles.multiplication}>{item.constant}</Text>
            <Text style={styles.newValue}>{item.newKWhNumber}</Text>
            <Text style={styles.oldValue}>{item.oldKWhNumber}</Text>
            <Text style={styles.used}>{item.consumption}</Text>
        </View>
    )

    return (<Fragment>{rows}</Fragment>)
};

export default TableRow