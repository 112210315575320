import React, { Component } from "react"
import { Switch } from "react-router-dom"
import { PublicRoute } from './PublicRoute'
import { PrivateRoute } from './PrivateRoute'
import { ExceptionRoute } from './ExceptionRoute'
import * as Auth from '@features/Auth'
import { ErrorPage } from "@features/Exceptions";
import { Analysis, Report, } from '../features/Tools'
import * as AssetPerformance from '../features/AssetPerformance'
import * as TechnicalMonitoring from '../features/TechnicalMonitoring'
import * as MiniScada from '../features/MiniScada'
import * as Billing from '../features/Billing'
import * as TicketsManagement from '../features/TicketsManagement'
import * as BillingConfigurations from '../features/Configurations/BillingConfigurations'
import { MonitoringRules, PortfolioConfigurations } from '../features/Configurations'
import { PERMISSIONS } from "./middleware"

class AllRoutes extends Component {
    render() {
        return (
            <Switch>
                <PublicRoute path="/login" layout='Auth'><Auth.Login/></PublicRoute>
                <PublicRoute path="/password-request" layout='Auth'>
                    <Auth.PasswordRequest/>
                </PublicRoute>
                <PublicRoute path="/password-reset" layout='Auth'>
                    <Auth.PasswordReset/>
                </PublicRoute>

                <PrivateRoute
                    path="/"
                    exact={true} permission={PERMISSIONS.TECHNICAL_MONITORING}
                    redirectUrl={"/technical-monitoring"}
                >
                    <TechnicalMonitoring.Index/>
                </PrivateRoute>

                <PrivateRoute
                    path="/technical-monitoring"
                    exact={true}
                    permission={PERMISSIONS.TECHNICAL_MONITORING}
                >
                    <TechnicalMonitoring.Index/>
                </PrivateRoute>
                <PrivateRoute
                    path="/technical-monitoring/site/:siteId"
                    exact={true}
                    permission={PERMISSIONS.TECHNICAL_MONITORING}
                >
                    <TechnicalMonitoring.DetailSite/>
                </PrivateRoute>
                <PrivateRoute
                    path="/technical-monitoring/plant/:plantId"
                    exact={true}
                    permission={PERMISSIONS.TECHNICAL_MONITORING}
                >
                    <TechnicalMonitoring.DetailPlant/>
                </PrivateRoute>

                <PrivateRoute
                    path="/mini-scada"
                    exact={true}
                    permission={PERMISSIONS.TECHNICAL_MONITORING}
                >
                    <MiniScada.Index/>
                </PrivateRoute>
                <PrivateRoute
                    path="/mini-scada/site/:siteId"
                    exact={true}
                    permission={PERMISSIONS.TECHNICAL_MONITORING}
                >
                    <MiniScada.DetailSite/>
                </PrivateRoute>
                <PrivateRoute
                    path="/mini-scada/plant/:plantId"
                    exact={true}
                    permission={PERMISSIONS.TECHNICAL_MONITORING}
                >
                    <MiniScada.DetailPlant/>
                </PrivateRoute>

                <PrivateRoute
                    path="/asset-performance"
                    exact={true}
                    permission={PERMISSIONS.ASSETS_PERFORMANCE}
                >
                    <AssetPerformance.Index/>
                </PrivateRoute>
                <PrivateRoute
                    path="/asset-performance/site/:siteId"
                    exact={true}
                    permission={PERMISSIONS.ASSETS_PERFORMANCE}
                >
                    <AssetPerformance.DetailSite/>
                </PrivateRoute>
                <PrivateRoute
                    path="/asset-performance/plant/:plantId"
                    exact={true}
                    permission={PERMISSIONS.ASSETS_PERFORMANCE}
                >
                    <AssetPerformance.DetailPlant/>
                </PrivateRoute>

                <PrivateRoute
                    path="/analysis"
                    exact={true}
                    permission={PERMISSIONS.ANALYSIS}
                >
                    <Analysis.Index/>
                </PrivateRoute>
                <PrivateRoute
                    path="/analysis/site/:siteId/"
                    exact={true}
                    permission={PERMISSIONS.ANALYSIS}
                >
                    <Analysis.DetailSite/>
                </PrivateRoute>
                <PrivateRoute
                    path="/analysis/plant/:plantId/"
                    exact={true}
                    permission={PERMISSIONS.ANALYSIS}
                >
                    <Analysis.DetailPlant/>
                </PrivateRoute>
                <PrivateRoute
                    path="/analysis/site/:siteId/:dataType"
                    exact={true}
                    permission={PERMISSIONS.ANALYSIS}
                >
                    <Analysis.DetailSite/>
                </PrivateRoute>
                <PrivateRoute
                    path="/analysis/plant/:plantId/:dataType"
                    exact={true}
                    permission={PERMISSIONS.ANALYSIS}
                >
                    <Analysis.DetailPlant/>
                </PrivateRoute>

                <PrivateRoute
                    path="/report"
                    exact={true}
                    permission={PERMISSIONS.REPORT}
                >
                    <Report.Index/>
                </PrivateRoute>
                <PrivateRoute
                    path="/report/site/:siteId"
                    exact={true}
                    permission={PERMISSIONS.REPORT}
                >
                    <Report.DetailSite/>
                </PrivateRoute>
                <PrivateRoute
                    path="/report/plant/:plantId"
                    exact={true}
                    permission={PERMISSIONS.REPORT}
                >
                    <Report.DetailPlant/>
                </PrivateRoute>

                <PrivateRoute
                    path="/billing"
                    exact={true}
                    permission={PERMISSIONS.BILLING}
                >
                    <Billing.Index/>
                </PrivateRoute>
                <PrivateRoute
                    path="/billing/site/:siteId"
                    exact={true}
                    permission={PERMISSIONS.BILLING}
                >
                    <Billing.DetailSite/>
                </PrivateRoute>
                <PrivateRoute
                    path="/billing/plant/:plantId"
                    exact={true}
                    permission={PERMISSIONS.BILLING}
                >
                    <Billing.DetailPlant/>
                </PrivateRoute>

                <PrivateRoute
                    path="/tickets-management"
                    exact={true}
                    permission={PERMISSIONS.TICKETS_MANAGEMENT}
                >
                    <TicketsManagement.Index/>
                </PrivateRoute>
                <PrivateRoute
                    path="/tickets-management/site/:siteId"
                    exact={true}
                    permission={PERMISSIONS.TICKETS_MANAGEMENT}
                >
                    <TicketsManagement.DetailSite/>
                </PrivateRoute>
                <PrivateRoute
                    path="/tickets-management/plant/:plantId"
                    exact={true}
                    permission={PERMISSIONS.TICKETS_MANAGEMENT}
                >
                    <TicketsManagement.DetailPlant/>
                </PrivateRoute>

                <PrivateRoute
                    path="/monitoring-rules"
                    exact={true}
                    permission={PERMISSIONS.MONITORING_RULES}
                >
                    <MonitoringRules.Index/>
                </PrivateRoute>
                <PrivateRoute
                    path="/monitoring-rules/site/:siteId"
                    exact={true}
                    permission={PERMISSIONS.MONITORING_RULES}
                >
                    <MonitoringRules.DetailSite/>
                </PrivateRoute>
                <PrivateRoute
                    path="/monitoring-rules/plant/:plantId"
                    exact={true}
                    permission={PERMISSIONS.MONITORING_RULES}
                >
                    <MonitoringRules.DetailPlant/>
                </PrivateRoute>

                <PrivateRoute
                    path="/billing-configurations"
                    exact={true}
                    permission={PERMISSIONS.BILLING_CONFIGURATIONS}
                >
                    <BillingConfigurations.Index/>
                </PrivateRoute>
                <PrivateRoute
                    path="/billing-configurations/site/:siteId"
                    exact={true}
                    permission={PERMISSIONS.BILLING_CONFIGURATIONS}
                >
                    <BillingConfigurations.DetailSite/>
                </PrivateRoute>
                <PrivateRoute
                    path="/billing-configurations/plant/:plantId"
                    exact={true}
                    permission={PERMISSIONS.BILLING_CONFIGURATIONS}
                >
                    <BillingConfigurations.DetailPlant/>
                </PrivateRoute>

                <PrivateRoute
                    path="/portfolio-configurations"
                    exact={true}
                    //permission={PERMISSIONS.PORTFOLIO_CONFIGURATIONS}
                    permission={PERMISSIONS.BILLING_CONFIGURATIONS}
                >
                    <PortfolioConfigurations.Index/>
                </PrivateRoute>
                <PrivateRoute
                    path="/portfolio-configurations/site/:siteId"
                    exact={true}
                    //permission={PERMISSIONS.PORTFOLIO_CONFIGURATIONS}
                    permission={PERMISSIONS.BILLING_CONFIGURATIONS}
                >
                    <PortfolioConfigurations.Index/>
                </PrivateRoute>
                <PrivateRoute
                    path="/portfolio-configurations/plant/:plantId"
                    exact={true}
                    //permission={PERMISSIONS.PORTFOLIO_CONFIGURATIONS}
                    permission={PERMISSIONS.BILLING_CONFIGURATIONS}
                >
                    <PortfolioConfigurations.Index/>
                </PrivateRoute>


                <ExceptionRoute path="*"><ErrorPage code={404}/></ExceptionRoute>
            </Switch>
        );
    }
}

export default AllRoutes
