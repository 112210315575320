/* CRUD ACTION */
export const PLANT_DETAIL = 'PLANT_DETAIL'
export const PLANT_DETAIL_LOADING = 'PLANT_DETAIL_LOADING'

/* CHART ACTION */
export const CHART_ACTUAL = 'CHART_ACTUAL'
export const CHART_ACTUAL_LOADING = 'CHART_ACTUAL_LOADING'

export const CHART_STRING_MONITORING = 'CHART_STRING_MONITORING'
export const CHART_STRING_MONITORING_LOADING = 'CHART_STRING_MONITORING_LOADING'

export const CHART_IV_CURVE = 'CHART_IV_CURVE'
export const CHART_IV_CURVE_LOADING = 'CHART_IV_CURVE_LOADING'

export const LIST_INVERTER_MONITORING = 'LIST_INVERTER_MONITORING'
export const LIST_INVERTER_MONITORING_LOADING = 'LIST_INVERTER_MONITORING_LOADING'

export const GAUGES_TECHNICAL_MONITORING = 'GAUGES_TECHNICAL_MONITORING'
export const GAUGES_TECHNICAL_MONITORING_LOADING = 'GAUGES_TECHNICAL_MONITORING_LOADING'

export const GAUGES_ASSET_PERFORMANCE = 'GAUGES_ASSET_PERFORMANCE'
export const GAUGES_ASSET_PERFORMANCE_LOADING = 'GAUGES_ASSET_PERFORMANCE_LOADING'

export const CHART_SPECIFIC_YIELD = 'CHART_SPECIFIC_YIELD'
export const CHART_SPECIFIC_YIELD_LOADING = 'CHART_SPECIFIC_YIELD_LOADING'


export const CHART_PERFORMANCE_RATIO_TRACKING = 'CHART_PERFORMANCE_RATIO_TRACKING'
export const CHART_PERFORMANCE_RATIO_TRACKING_LOADING = 'CHART_PERFORMANCE_RATIO_TRACKING_LOADING'

export const CHART_ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION = 'CHART_ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION'
export const CHART_ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION_LOADING = 'CHART_ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION_LOADING'

export const CHART_CARBON_OFFSET = 'CHART_CARBON_OFFSET'
export const CHART_CARBON_OFFSET_LOADING = 'CHART_CARBON_OFFSET_LOADING'

export const CHART_DAILY_IO_DIAGRAM = 'CHART_DAILY_IO_DIAGRAM'
export const CHART_DAILY_IO_DIAGRAM_LOADING = 'CHART_DAILY_IO_DIAGRAM_LOADING'

export const LIST_INVERTER_PERFORMANCE = 'LIST_INVERTER_PERFORMANCE'
export const LIST_INVERTER_PERFORMANCE_LOADING = 'LIST_INVERTER_PERFORMANCE_LOADING'

export const GET_PLANT_INVERTER_ACTION = 'GET_PLANT_INVERTER_ACTION';
export const GET_PLANT_INVERTER_ACTION_LOADING = 'GET_PLANT_INVERTER_ACTION_LOADING';

export const GET_PLANT_OVER_VIEW_DATA_ACTION = 'GET_PLANT_OVER_VIEW_DATA_ACTION';
export const GET_PLANT_OVER_VIEW_DATA_ACTION_LOADING = 'GET_PLANT_OVER_VIEW_DATA_ACTION_LOADING';

export const GET_PLANT_DATA_OVER_DETAIL_ACTION = 'GET_PLANT_DATA_OVER_DETAIL_ACTION';
export const GET_PLANT_DATA_OVER_DETAIL_ACTION_LOADING = 'GET_PLANT_DATA_OVER_DETAIL_ACTION_LOADING';

export const REFRESH_PLANT_DATA_OVERVIEW_ACTION = 'REFRESH_PLANT_DATA_OVERVIEW_ACTION';
export const REFRESH_PLANT_DATA_OVERVIEW_ACTION_LOADING = 'REFRESH_PLANT_DATA_OVERVIEW_ACTION_LOADING';

export const FIND_DATA_OVERVIEW_ACTION = 'FIND_DATA_OVERVIEW_ACTION';
export const FIND_DATA_OVERVIEW_ACTION_LOADING = 'FIND_DATA_OVERVIEW_ACTION_LOADING';
