import React, { Component } from 'react';
import {
    AntCard,
    AntForm,
    AntFormItem,
    AntInput,
    FormGroupActionUpdate,
} from "@layouts";

import { PlusOutlined } from '@ant-design/icons';
import { message, Modal, Upload } from 'antd';
import { connect } from "react-redux";
import Loading from "@src/layouts/LoadingMain/Component";
import helpers from "@ultis/helpers";
import { updateTokenAction } from "@features/Auth/redux/actions";

class CustomComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFileList: [],
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
        }
    }

    render() {
        let {
            onFinishForm,
            portfolioConfigs
        } = this.props

        let {
            detail,
            update,
        } = portfolioConfigs


        let detailData = detail.data
        let detailLoading = detail.loading
        let { id, name } = detailData

        let updateErrors = update.errors
        let updateLoading = update.loading
        updateErrors = updateErrors ?? {};

        const { selectedFileList, previewVisible, previewImage, previewTitle } = this.state

        return (
            <AntCard
                title={"Portfolio information"}
                bordered={true}
                loading={detailLoading}
            >
                {
                    detailLoading ? <Loading/> : <AntForm
                        className="form-center form-portfolio-configs"
                        onFinish={onFinishForm}
                        initialValues={{ id: id, name: name }}
                        {...layout}
                    >
                        <AntFormItem
                            hidden={true}
                            name="id"
                        >
                            <AntInput/>
                        </AntFormItem>
                        <AntFormItem
                            required={true}
                            label="Portfolio Name"
                            name="name"
                            errors={updateErrors.name}
                        >
                            <AntInput/>
                        </AntFormItem>
                        <AntFormItem
                            label="Logo"
                            name="logo"
                            errors={updateErrors.logo}
                        >
                            <Upload
                                fileList={selectedFileList}
                                listType="picture-card"
                                beforeUpload={this.beforeUpload}
                                onChange={this.handleChange}
                                customRequest={this.customRequest}
                                onPreview={this.handlePreview}
                            >
                                {selectedFileList.length >= 1 ? null : <UploadButton/>}
                            </Upload>
                        </AntFormItem>
                        <AntFormItem {...tailLayout}>
                            <FormGroupActionUpdate loading={updateLoading}/>
                        </AntFormItem>
                    </AntForm>
                }

                <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={this.handleCancel}>
                    <PreviewImage src={previewImage}/>
                </Modal>
            </AntCard>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        // Load detail portfolio form
        const currentDetail = this.props.portfolioConfigs.detail.data ?? {};
        const prevDetail = prevProps.portfolioConfigs.detail.data ?? {}
        if (currentDetail !== prevDetail) {
            let { logoUrl, name } = currentDetail

            // Check is valid url
            if (logoUrl) {
                // Need init file
                let initFile = {
                    uid: '-1',
                    name: name,
                    status: 'done',
                    url: logoUrl,
                };
                this.setState({
                    ...this.state,
                    selectedFileList: [initFile]
                })
            }
        }

        // Reload logo when update success
        const currentUpdate = this.props.portfolioConfigs.update.data ?? {};
        const prevUpdate = prevProps.portfolioConfigs.update.data ?? {}

        if (currentUpdate.logoUrl !== prevUpdate.logoUrl) {
            this.props.updateTokenAction({ ...currentUpdate })
        }
    }

    handleChange = ({ fileList: newFileList }) => {
        this.setState({
            ...this.state,
            selectedFileList: newFileList
        })
    };

    /**
     * Custom handel upload
     * @param onSuccess
     * @param onError
     * @param file
     */
    customRequest = ({ onSuccess, onError, file }) => {
        let { selectedFileList } = this.state
        try {
            let currentFile = selectedFileList.find((item) => {
                return item.uid = file.uid
            });
            currentFile.status = "done";
            this.setState({
                ...this.state,
                selectedFileList: selectedFileList
            })
        } catch (e) {
            console.log(e)
        }
    };

    /**
     * Validate image
     * @param file
     * @returns {boolean}
     */
    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }

        return isJpgOrPng && isLt2M;
    };

    handleCancel = () => {
        this.setState({
            ...this.state,
            previewVisible: false
        })
    };

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await helpers.getBase64(file.originFileObj)
        }

        this.setState({
            ...this.state,
            previewVisible: true,
            previewImage: file.url || file.preview,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        })
    };
}

const PreviewImage = (props) => (
    <img
        src={props.src}
        alt={props.alt}
        style={{ width: '100%' }}
    />
)

const UploadButton = () => (
    <div>
        <PlusOutlined/>
        <div
            style={{
                marginTop: 8,
            }}
        >
            Upload
        </div>
    </div>
);

const layout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 6,
        span: 16,
    },
};


function mapDispatchToProps(dispatch) {
    return {
        updateTokenAction: (params) => {
            dispatch(updateTokenAction(params));
        },
    };
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        portfolioConfigs: state.portfolioConfigs,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomComponent)
