import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import {
    DashboardOutlined,
    DeploymentUnitOutlined,
    DollarCircleOutlined,
    LogoutOutlined,
    UserOutlined,
    ToolOutlined,
    TagsOutlined,
    SettingOutlined,
    SlidersOutlined, EuroCircleOutlined, FileOutlined, UnorderedListOutlined, FileTextOutlined, FileSearchOutlined,
} from '@ant-design/icons';
import { Link } from "react-router-dom";
import { AntAvatar } from "../AntAvatar";
import { PERMISSIONS } from "@routes/middleware";

class President extends Component {
    renderMenuItems = (routes) => {
        const {
            technicalMonitoringRoute,
            miniScadaRoute,
            assetPerformanceRoute,
            analysisRoute,
            reportRoute,
            billingRoute,
            ticketsManagementRoute,
            monitoringRulesRoute,
            billingConfigurationsRoute,
            portfolioConfigurationsRoute,
        } = routes
        return [
            getItem(<Link to={technicalMonitoringRoute}>Technical Monitoring</Link>, technicalMonitoringRoute,
                <DashboardOutlined/>),
            getItem(<Link to={miniScadaRoute}>Mini SCADA</Link>, miniScadaRoute,
                <SlidersOutlined/>),
            getItem(<Link to={assetPerformanceRoute}>Asset Performance</Link>, assetPerformanceRoute,
                <DeploymentUnitOutlined/>),
            getItem(<Link to={billingRoute}>Billing</Link>, billingRoute,
                <DollarCircleOutlined/>),
            getItem('Tools', 'tools', <ToolOutlined/>, [
                getItem(<Link to={analysisRoute}>Analysis</Link>, analysisRoute, <FileSearchOutlined/>),
                getItem(<Link to={reportRoute}>Report</Link>, reportRoute, <FileTextOutlined/>),
            ]),
            getItem(<Link to={ticketsManagementRoute}>Tickets Management</Link>, ticketsManagementRoute,
                <TagsOutlined/>),
            getItem('Configurations', 'configurations', <SettingOutlined/>, [
                getItem(<Link to={portfolioConfigurationsRoute}>Portfolio</Link>, portfolioConfigurationsRoute,
                    <FileOutlined/>),
                getItem(<Link to={billingConfigurationsRoute}>Billing</Link>, billingConfigurationsRoute,
                    <EuroCircleOutlined/>),
                getItem(<Link to={monitoringRulesRoute}>Monitoring Rules</Link>, monitoringRulesRoute,
                    <UnorderedListOutlined/>),
            ]),
        ];
    }

    renderUserMenuItems = (params) => {
        const { fullName, collapsed, randomAvatar, handleLogout } = params;
        return [
            {
                type: "divider", // Must have
            },
            {
                type: "group", // Must have
                key: "account",
                label: <div className={collapsed ? "sub-collapsed" : ""}><UserOutlined/> Account</div>,
                children: [
                    getItem(fullName, "account-user", <AntAvatar
                        alt={fullName}
                        icon={<UserOutlined/>}
                        size="small"
                        src={randomAvatar}
                    />),
                    getItem(<div onClick={handleLogout}>Logout</div>, "account-logout", <LogoutOutlined/>),
                ],
            },
        ];
    }

    render() {
        const {
            router,
            common,
            auth,
            match,
            randomAvatar,
            handleLogout
        } = this.props;

        const pathname = router.location.pathname;
        const { plantId, siteId } = match.params;

        // Layout
        const collapsed = common.siderCollapsed;

        // Auth
        const { user } = auth;
        const full_name = user.name;

        let selectDefault;
        let openDefault;
        let prefix = '/'
        let suffixScope = '';
        switch (true) {
            case plantId !== undefined:
                suffixScope += `/plant/${plantId}`
                break
            case siteId !== undefined:
                suffixScope += `/site/${siteId}`
                break
            default:
                break;
        }

        let technicalMonitoringRoute = PERMISSIONS.TECHNICAL_MONITORING;
        let miniScadaRoute = PERMISSIONS.MINI_SCADA;
        let assetPerformanceRoute = PERMISSIONS.ASSETS_PERFORMANCE;
        let analysisRoute = PERMISSIONS.ANALYSIS;
        let reportRoute = PERMISSIONS.REPORT;
        let assetManagementRoute = PERMISSIONS.ASSET_MANAGEMENT;
        let billingRoute = PERMISSIONS.BILLING;
        let ticketsManagementRoute = PERMISSIONS.TICKETS_MANAGEMENT;
        let monitoringRulesRoute = PERMISSIONS.MONITORING_RULES;
        let billingConfigurationsRoute = PERMISSIONS.BILLING_CONFIGURATIONS;
        let portfolioConfigurationsRoute = PERMISSIONS.PORTFOLIO_CONFIGURATIONS;

        technicalMonitoringRoute = prefix + technicalMonitoringRoute + suffixScope;
        miniScadaRoute = prefix + miniScadaRoute + suffixScope;
        assetPerformanceRoute = prefix + assetPerformanceRoute + suffixScope;
        analysisRoute = prefix + analysisRoute + suffixScope;
        reportRoute = prefix + reportRoute + suffixScope;
        assetManagementRoute = prefix + assetManagementRoute + suffixScope;
        billingRoute = prefix + billingRoute + suffixScope;
        ticketsManagementRoute = prefix + ticketsManagementRoute + suffixScope;
        monitoringRulesRoute = prefix + monitoringRulesRoute + suffixScope;
        billingConfigurationsRoute = prefix + billingConfigurationsRoute + suffixScope;
        portfolioConfigurationsRoute = prefix + portfolioConfigurationsRoute;

        // Render open default && select default
        switch (true) {
            case pathname === "/" || pathname.indexOf(technicalMonitoringRoute) !== -1:
                selectDefault = technicalMonitoringRoute
                break;
            case pathname.indexOf(miniScadaRoute) !== -1:
                selectDefault = miniScadaRoute
                break;
            case pathname.indexOf(assetPerformanceRoute) !== -1:
                openDefault = 'dashboard'
                selectDefault = assetPerformanceRoute
                break;
            case pathname.indexOf(analysisRoute) !== -1:
                openDefault = 'tools'
                selectDefault = analysisRoute
                break;
            case pathname.indexOf(reportRoute) !== -1:
                openDefault = 'tools'
                selectDefault = reportRoute
                break;
            case pathname.indexOf(assetManagementRoute) !== -1:
                selectDefault = assetManagementRoute
                break;
            case pathname.indexOf(billingRoute) !== -1 && pathname.indexOf('configurations') === -1:
                selectDefault = billingRoute
                break;
            case pathname.indexOf(ticketsManagementRoute) !== -1:
                selectDefault = ticketsManagementRoute
                break;
            case pathname.indexOf(monitoringRulesRoute) !== -1:
                openDefault = 'configurations'
                selectDefault = monitoringRulesRoute
                break;
            case pathname.indexOf(billingConfigurationsRoute) !== -1:
                openDefault = 'configurations'
                selectDefault = billingConfigurationsRoute
                break;
            case pathname.indexOf(portfolioConfigurationsRoute) !== -1:
                openDefault = 'configurations'
                selectDefault = portfolioConfigurationsRoute
                break;
            default:
                break;
        }

        return (
            <Layout.Sider
                className="left-slider"
                width={220}
                trigger={null}
                collapsible
                collapsed={collapsed}
            >

                {/* <div className="logo" />
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['4']} items={items} />*/}
                <Menu
                    mode="inline"
                    selectedKeys={[selectDefault]}
                    defaultSelectedKeys={[selectDefault]}
                    defaultOpenKeys={collapsed ? [] : [openDefault]}
                    selectable={true}
                    items={this.renderMenuItems({
                        "technicalMonitoringRoute": technicalMonitoringRoute,
                        "miniScadaRoute": miniScadaRoute,
                        "assetPerformanceRoute": assetPerformanceRoute,
                        "analysisRoute": analysisRoute,
                        "reportRoute": reportRoute,
                        "billingRoute": billingRoute,
                        "ticketsManagementRoute": ticketsManagementRoute,
                        "monitoringRulesRoute": monitoringRulesRoute,
                        "billingConfigurationsRoute": billingConfigurationsRoute,
                        "portfolioConfigurationsRoute": portfolioConfigurationsRoute,
                    })}
                />
                <Menu
                    className={"menu-account " + (collapsed ? "sub-collapsed" : "")}
                    mode="inline"
                    selectable={false}
                    defaultOpenKeys={collapsed ? [] : ['account']}
                    items={this.renderUserMenuItems({
                        fullName: full_name,
                        collapsed: collapsed,
                        randomAvatar: randomAvatar,
                        handleLogout: handleLogout,
                    })}
                />
            </Layout.Sider>
        )
    }
}

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

export default President;