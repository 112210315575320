import React, { Component } from 'react';
import { ToolboxControl } from "@layouts";
import { connect } from "react-redux";
import { AreaChartOutlined } from "@ant-design/icons";
import { AntCard, Loading } from "@layouts";
import * as Plant from "@features/Plant";

class CustomComponent extends Component {
    render() {
        const { common, meta, defaultTypeString, handleChangeTypeString } = this.props;

        const parentElementId = "plant-string-monitoring";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        // Init chart data
        const { loading, options } = meta.stringMonitoring;
        let max = 1e9;

        switch (defaultTypeString) {
            case 'PVampe':
                max = 16;
                break;
            case 'PVvolt':
                max = 1000;
                break;
            default:
                break;
        }

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <AreaChartOutlined/> String Monitoring
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            typeStringOptions={[{ name: 'PV-Ampe', value: 'PVampe' }, { name: 'Pv-Volt', value: 'PVvolt' }]}
                            defaultTypeString={defaultTypeString}
                            handleChangeTypeString={handleChangeTypeString}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-width">
                    {loading ? <Loading overwrite/> : null}
                    <Plant.ChartStringMonitoring options={options} max={max}/>
                </div>
            </AntCard>)
    }
}

function mapStateToProps(state) {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);