import * as CONSTANTS from "./constants";
import { apiGet, apiPut } from "@common/crud";
import helpers from "@ultis/helpers";

/**
 * Get list
 * @param plantId
 * @returns {(function(*): void)|*}
 */
export function reportGetList(plantId) {
    return dispatch => {
        dispatch(reportGetListLoadingAction())
        dispatch(apiGet('reports', { plantId: plantId }, {}, reportGetListAction))
    }
}

/**
 * Dispatch Get list action
 * @param response
 * @returns {{payload, type: string}}
 */
function reportGetListAction(response) {
    return {
        type: CONSTANTS.REPORT_GET_LIST,
        payload: response
    };
}

/**
 * Dispatch Get list loading action
 * @returns {{payload: null, type: string}}
 */
function reportGetListLoadingAction() {
    return {
        type: CONSTANTS.REPORT_GET_LIST_LOADING,
        payload: null
    };
}

/**
 * Get Detail
 * @param reportId
 * @returns {(function(*): void)|*}
 */
export function reportGetDetail(reportId) {
    return dispatch => {
        dispatch(reportGetDetailLoadingAction())
        dispatch(apiGet(`reports/${reportId}`, {}, {}, reportGetDetailAction))
    }
}

/**
 * Dispatch Get Detail action
 * @param response
 * @returns {{payload, type: string}}
 */
function reportGetDetailAction(response) {
    return {
        type: CONSTANTS.REPORT_GET_DETAIL,
        payload: response
    };
}

/**
 * Dispatch Get Detail loading action
 * @returns {{payload: null, type: string}}
 */
function reportGetDetailLoadingAction() {
    return {
        type: CONSTANTS.REPORT_GET_DETAIL_LOADING,
        payload: null
    };
}

/**
 * Update
 * @param reportId
 * @param params
 * @returns {(function(*): void)|*}
 */
export function reportUpdate(reportId, params) {
    return dispatch => {
        dispatch(reportUpdateLoadingAction())
        dispatch(apiPut(`reports/${reportId}`, {
            activeAutomatic: params.activeAutomatic ?? null,
            fileType: params.fileType ?? [],
            pattern: {
                type: params.patternType ?? null,
                value: params.patternValue ?? null
            }
        }, {}, reportUpdateAction))
    }
}

/**
 * Dispatch Update action
 * @param response
 * @returns {{payload, type: string}}
 */
function reportUpdateAction(response) {
    return {
        type: CONSTANTS.REPORT_UPDATE,
        payload: response
    };
}

/**
 * Dispatch Update loading action
 * @returns {{payload: null, type: string}}
 */
function reportUpdateLoadingAction() {
    return {
        type: CONSTANTS.REPORT_UPDATE_LOADING,
        payload: null
    };
}

/**
 * Request
 * @param reportId
 * @param params
 * @returns {(function(*): void)|*}
 */
export function reportRequestDownload(reportId, params) {
    return dispatch => {
        dispatch(reportRequestDownloadLoadingAction())
        dispatch(apiGet(`reports/${reportId}/download`, params, {}, reportRequestDownloadAction))
    }
}

/**
 * Dispatch download action
 * @param response
 * @returns {{payload, type: string}}
 */
function reportRequestDownloadAction(response) {
    let data = response.data ?? {}
    let url = data.url ?? null;
    if (url) {
        helpers.download(url, url.split('/').reverse()[0])
    }
    return {
        type: CONSTANTS.REPORT_REQUEST_DOWNLOAD,
        payload: response
    };
}

/**
 * Dispatch download loading action
 * @returns {{payload: null, type: string}}
 */
function reportRequestDownloadLoadingAction() {
    return {
        type: CONSTANTS.REPORT_REQUEST_DOWNLOAD_LOADING,
        payload: null
    };
}
