import * as CONSTANTS from "./constants";
import { apiGet } from "@common/crud";
import { DATA_TYPE } from "@ultis/constants";

export function chartList(params) {
    return dispatch => {
        dispatch(chartListActionLoading())
        let { scope } = params
        switch (scope) {
            case "plant":
                dispatch(apiGet(`analysis/select/${scope}`, {}, {}, listByPlantAction))
                break;
            case "site":
                dispatch(apiGet(`analysis/select/${scope}`, {}, {}, listBySiteAction))
                break;
            default:
                break
        }
    }
}

export function listByPlantAction(response) {
    return {
        type: CONSTANTS.ANALYSIS_LIST_BY_PLANT,
        //payload: response.data
        payload: mockupPlant
    };
}

export function listBySiteAction(response) {
    return {
        type: CONSTANTS.ANALYSIS_LIST_BY_SITE,
        payload: response.data
    };
}

export function chartListActionLoading() {
    return {
        type: CONSTANTS.ANALYSIS_LIST_LOADING,
        payload: null
    };
}

// Mockup
const mockupPlant = [
    {
        "title": "Technical Monitoring",
        "features": [
            {
                "title": "Actual Production & Actual Irradiation",
                "dataType": "actual_production_and_irradiation",
                "scope": "plant",
                "selected": 1
            },
            {
                "title": "String Monitoring",
                "dataType": "string_monitoring",
                "scope": "plant",
                "selected": 0
            },
            {
                "title": "I-V Curve",
                "dataType": DATA_TYPE.IV_CURVE,
                "scope": "plant",
                "selected": 0
            },
        ]
    },
    {
        "title": "Asset Performance",
        "features": [
            {
                "title": "Performance Ratio Tracking",
                "dataType": "performance_ratio_tracking",
                "scope": "plant",
                "selected": 0
            },
            {
                "title": "Actual Production vs Budget Production",
                "dataType": "actual_production_vs_budget_production",
                "scope": "plant",
                "selected": 0
            },
            {
                "title": "Carbon offset",
                "dataType": "carbon_offset",
                "scope": "plant",
                "selected": 0
            },
            {
                "title": "Specific Yield",
                "dataType": "specific_yield",
                "scope": "plant",
                "selected": 0
            },
            {
                "title": "Daily IO Diagram",
                "dataType": "daily_io_diagram",
                "scope": "plant",
                "selected": 0
            }
        ]
    }
]