import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { fetchPlants } from "../../redux";
import { resetStore } from "@features/Common/redux";
import { withRouter } from "react-router-dom";
import { fetchTableEvent } from "@features/Event/redux";
import * as CONSTANTS from "@ultis/constants";
import { getPlantDataOverview } from "@features/Plant/redux";

class Container extends Component {
    onChangeResizeMap = () => {
        this.props.fetchPlants();
    }

    render() {
        return (
            <President
                {...this.props}
                showReload={true}
                onChangeResizeMap={this.onChangeResizeMap}
                getPlantDataOverview={this.props.getPlantDataOverview}
            />
        )
    }

    componentDidMount() {
        this.fetchData();
        this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, () => this.fetchData())
    }

    componentWillUnmount() {
        this.props.resetStore()
    }

    fetchData = () => {
        // Get route params
        let { plantId, siteId } = this.props.match.params;

        const { user } = this.props.auth;
        plantId = plantId ?? null;
        siteId = siteId ?? null;

        this.props.getPlantDataOverview({ siteId: siteId, type: "overview" });

        this.props.fetchPlants();

        if (user.id !== "65b1cb6c14f538c47f38a68b") {
            this.props.fetchTableEvent({
                siteId: siteId,
                plantId: plantId,
            });
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        resetStore: () => {
            dispatch(resetStore());
        },
        fetchPlants: (siteId) => {
            dispatch(fetchPlants(siteId));
        },
        fetchTableEvent: (params) => {
            dispatch(fetchTableEvent(params));
        },
        getPlantDataOverview: params => {
            dispatch(getPlantDataOverview(params));
        }
    };
}

function mapStateToProps(state) {
    return {
        socketIO: state.socketIO,
        common: state.common,
        technicalMonitoring: state.technicalMonitoring,
        event: state.event,
        plant: state.plant,
        auth: state.auth,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container))