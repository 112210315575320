import React, { Component } from 'react';

class President extends Component {
    render() {
        return (
            <div/>
        )
    }
}

export default President;