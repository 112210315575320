import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import {
    getPlantData,
    getPlantDetail,
} from "@features/Plant/redux";
import * as CONSTANTS from "@ultis/constants";

class Container extends Component {
    constructor(props) {
        super(props);

        // Load default roles
        let selectedRoles = {}
        selectedRoles[CONSTANTS.ROLES.OPERATION_AND_MAINTENANCE_MANAGER] = true;
        selectedRoles[CONSTANTS.ROLES.ASSET_MANAGER] = true;
        selectedRoles[CONSTANTS.ROLES.ENERGY_MANAGER] = true;

        this.state = {
            isVisibleTicket: false,
            selectedRoles: selectedRoles,
            ticketParams: {
                keyword: "",
                ticketStatus: [10, 20],
                equipment: ["all", "inverter"],
                dueOnFrom: null,
                dueOnTo: null,
            },
        }
    }

    onChangeKeyword = (e) => {
        const value = e.currentTarget.value
        this.setState({
            ...this.state,
            ticketParams: {
                ...this.state.ticketParams,
                keyword: value,
            }
        })
    }

    onChangeDueOn = (dates) => {
        let from = dates ? dates[0] : null
        let to = dates ? dates[1] : null
        this.setState({
            ...this.state,
            ticketParams: {
                ...this.state.ticketParams,
                dueOnFrom: from,
                dueOnTo: to,
            }
        })
    }

    onChangeTicketStatus = (options) => {
        this.setState({
            ...this.state,
            ticketParams: {
                ...this.state.ticketParams,
                ticketStatus: options,
            }
        })
    }

    onChangeDeviceType = (options) => {
        this.setState({
            ...this.state,
            ticketParams: {
                ...this.state.ticketParams,
                equipment: options,
            }
        })
    }

    /**
     * On select roles
     * @param e
     */
    onSelectedRoles = (e) => {
        let selectedRoles = { ...this.state.selectedRoles }
        selectedRoles[e.target.name] = e.target.checked
        this.setState({
            ...this.state,
            selectedRoles: selectedRoles
        })
    }

    /**
     * On click new ticket
     */
    onClickNewTicket = () => {
        this.setState({
            ...this.state,
            isVisibleTicket: true
        })
    }

    /**
     * On click edit ticket
     */
    onClickEditTicket = () => {
        this.setState({
            ...this.state,
            isVisibleTicket: true
        })
    }

    /**
     * On submit form ticket
     */
    onSubmitTicket = () => {
        console.log("onSubmitTicket")
    }

    /**
     * On click hide ticket
     */
    onCloseTicket = () => {
        this.setState({
            ...this.state,
            isVisibleTicket: false
        })
    }

    getPlantTicketsByDate = (date) => {
        this.setState({
            ...this.state,
            ticketParams: {
                ...this.state.ticketParams,
                dueOnFrom: date,
                dueOnTo: date,
            }
        })
    }

    onChangeTableTickets = (pagination, filters, sorter) => {
        this.props.fetchTableProduct({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };

    render() {
        const {
            detail,
            meta,
        } = this.props.plant

        if (!detail.isFound) {
            return (
                <Redirect to="/errors/404"/>
            )
        }
        return (<President
            {...this.state}
            detail={detail}
            meta={meta}

            onSelectedRoles={this.onSelectedRoles}
            getPlantTicketsByDate={this.getPlantTicketsByDate}
            ticketsDue={ticketsDue}
            assignGroups={assignGroups}

            onChangeKeyword={this.onChangeKeyword}
            onChangeDueOn={this.onChangeDueOn}
            onChangeTicketStatus={this.onChangeTicketStatus}
            onChangeDeviceType={this.onChangeDeviceType}
            onChangeTableTickets={this.onChangeTableTickets}

            onClickNewTicket={this.onClickNewTicket}
            onClickEditTicket={this.onClickEditTicket}
            onSubmitTicket={this.onSubmitTicket}
            onCloseTicket={this.onCloseTicket}

            currentUser={this.props.auth.user}
            ticket={ticket}

            listEvent={[]}
            listSLA={listSLA}
            listEquipment={listEquipment}
            listStatus={listStatus}
        />)
    }

    componentDidMount() {
        this.fetchData();
        this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, () => this.fetchData())
    }

    componentDidUpdate(prevProps) {
        const preParams = prevProps.match.params;
        const currentParams = this.props.match.params;

        if ((preParams.plantId !== currentParams.plantId)) {
            this.fetchData();
        }
    }

    fetchData = () => {
        const { plantId } = this.props.match.params;

        // Fetch data
        this.props.getPlantDetail(plantId)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getPlantDetail: (plantId) => {
            dispatch(getPlantDetail(plantId));
        },

        getPlantData: (params) => {
            dispatch(getPlantData(params));
        },
    };
}


function mapStateToProps(state) {
    return {
        socketIO: state.socketIO,
        common: state.common,
        auth: state.auth,
        plant: state.plant,
        ticketsManagement: state.ticketsManagement,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container))

const ticket = {
    "id": 3,
    "plantId": "6294ed3c876f1480e8dac40a",
    "slaId": 40,
    "status": 10,
    "equipmentId": 1,
    "name": "Ground fall at String 1 of Inverter #10",
    "assignUserId": 1,
    "ownerUser": "Mr. X",
    "startDate": "01-07-2022",
    "duaDate": "07-07-2022",
    "description": "This is current description.",
    "updateAt": "2022-07-08 10:15:23",
    "histories": [
        {
            "id": 2,
            "status": 1,
            "description": "This is update description. This is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            "updateAt": "2022-07-03 15:15:23"
        },
        {
            "id": 1,
            "status": 1,
            "description": "This is first description. This is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            "updateAt": "2022-07-01 10:15:23"
        }
    ]
}

const listStatus = [
    {
        "id": 1,
        "name": "Open",
    },
    {
        "id": 2,
        "name": "Processing",
    },
    {
        "id": 3,
        "name": "Close",
    },
];

const listEquipment = [
    {
        "id": 1,
        "name": "SUN2000-1",
    },
    {
        "id": 2,
        "name": "SUN2000-2",
    },
    {
        "id": 3,
        "name": "SUN2000-3",
    },
    {
        "id": 4,
        "name": "SUN2000-4",
    },
    {
        "id": 5,
        "name": "SUN2000-5",
    },
    {
        "id": 6,
        "name": "SUN2000-6",
    },
    {
        "id": 7,
        "name": "SUN2000-7",
    },
    {
        "id": 8,
        "name": "SUN2000-8",
    },
    {
        "id": 9,
        "name": "SUN2000-9",
    },
    {
        "id": 10,
        "name": "SUN2000-10",
    },
]

const listSLA = [
    {
        "id": 1,
        "name": "5 Days",
        "priority": 10,
    },
    {
        "id": 2,
        "name": "3 Days",
        "priority": 20,
    },
    {
        "id": 3,
        "name": "24 Hours",
        "priority": 30,
    },
    {
        "id": 4,
        "name": "8 Hours",
        "priority": 40,
    }
]

const assignGroups = [
    {
        "roleId": CONSTANTS.ROLES.OPERATION_AND_MAINTENANCE_MANAGER,
        "roleName": "Operation & Maintenance",
        "users": [
            {
                "id": "1",
                "name": "Nguyễn Văn A",
                "title": "Technician 1",
                "phone": "090 1234 567",
                "email": "email@example.com",
            },
            {
                "id": "2",
                "name": "Nguyễn Văn B",
                "title": "Technician 2",
                "phone": "090 1234 567",
                "email": "email@example.com",
            },
        ]
    },
    {
        "roleId": CONSTANTS.ROLES.ASSET_MANAGER,
        "roleName": "Asset Manager",
        "users": [
            {
                "id": "3",
                "name": "Nguyễn Văn C",
                "title": "Technician 1",
                "phone": "090 1234 567",
                "email": "email@example.com",
            },
            {
                "id": "4",
                "name": "Nguyễn Văn D",
                "title": "Technician 2",
                "phone": "090 1234 567",
                "email": "email@example.com",
            },
            {
                "id": "5",
                "name": "Nguyễn Văn E",
                "title": "Technician 3",
                "phone": "090 1234 567",
                "email": "email@example.com",
            },
        ]
    },
    {
        "roleId": CONSTANTS.ROLES.ENERGY_MANAGER,
        "roleName": "Energy Manager",
        "users": [
            {
                "id": "6",
                "name": "Nguyễn Văn F",
                "title": "Technician 1",
                "phone": "090 1234 567",
                "email": "email@example.com",
            },
            {
                "id": "7",
                "name": "Nguyễn Văn G",
                "title": "Technician 2",
                "phone": "090 1234 567",
                "email": "email@example.com",
            },
        ]
    }
]

const ticketsDue = {
    "2022-07-07": [
        {
            "id": "1",
            "dueDate": "2022-07-07",
            "type": "warning",
            "name": "Lau chùi định kỳ pin.",
            "assignUserId": "1",
            "assignUserName": "Nguyễn Văn A",
            "assignUserRole": CONSTANTS.ROLES.OPERATION_AND_MAINTENANCE_MANAGER,
            "createdBy": "Mr. X",
            "createdAt": "2022-07-01",
        },
        {
            "id": "2",
            "dueDate": "2022-07-07",
            "type": "warning",
            "name": "Thay thiết bị A.",
            "assignUserId": "1",
            "assignUserName": "Nguyễn Văn B",
            "assignUserRole": CONSTANTS.ROLES.ASSET_MANAGER,
            "createdBy": "Mr. X",
            "createdAt": "2022-07-01",
        }
    ],
    "2022-07-10": [
        {
            "id": "3",
            "dueDate": "2022-07-10",
            "type": "warning",
            "name": "Lau chùi định kỳ pin.",
            "assignUserId": "1",
            "assignUserName": "Nguyễn Văn C",
            "assignUserRole": CONSTANTS.ROLES.OPERATION_AND_MAINTENANCE_MANAGER,
            "createdBy": "Mr. X",
            "createdAt": "2022-07-01",
        },
        {
            "id": "4",
            "dueDate": "2022-07-10",
            "type": "warning",
            "name": "Thay thiết bị A.",
            "assignUserId": "1",
            "assignUserName": "Nguyễn Văn D",
            "assignUserRole": CONSTANTS.ROLES.ENERGY_MANAGER,
            "createdBy": "Mr. X",
            "createdAt": "2022-07-01",
        }
    ],
    "2022-07-15": [
        {
            "id": "5",
            "dueDate": "2022-07-10",
            "type": "warning",
            "name": "Lau chùi định kỳ pin.",
            "assignUserId": "1",
            "assignUserName": "Nguyễn Văn E",
            "assignUserRole": CONSTANTS.ROLES.ASSET_MANAGER,
            "createdBy": "Mr. X",
            "createdAt": "2022-07-01",
        },
        {
            "id": "6",
            "dueDate": "2022-07-10",
            "type": "warning",
            "name": "Thay thiết bị sau.",
            "assignUserId": "1",
            "assignUserName": "Nguyễn Văn F",
            "assignUserRole": CONSTANTS.ROLES.ENERGY_MANAGER,
            "createdBy": "Mr. X",
            "createdAt": "2022-07-01",
        }
    ]
}