import React, { Component } from 'react';
import { ToolboxControl, AntCard, Loading } from "@layouts";
import { connect } from "react-redux";
import { DashboardOutlined, BarChartOutlined } from "@ant-design/icons";
import { Col, Divider, Row } from "antd";

class CustomComponent extends Component {
    render() {
        const { common, meta } = this.props;
        const parentElementId = "plant-gauges";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        // Init data
        const {
            loading,
            lastTime,
            performanceRatioMonthToDate,
            performanceRatioToday,
            performanceRatioYearToDate,
            performanceRatioYesterday,
            specificYieldMonthToDate,
            specificYieldToday,
            specificYieldYearToDate,
            specificYieldYesterday,
        } = meta.gaugesPerformance;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <DashboardOutlined/> Gauges
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            updateAt={lastTime}
                        />
                    </div>
                }
            >
                <div className="card-custom-body height-350">
                    {loading ? <Loading overwrite/> : null}
                    <div className="group-gauges group-output">
                        <div className="group-title">
                            <DashboardOutlined/> Specific Yield
                        </div>
                        <Row className="group-value">
                            <Col xs={12} xl={12} className="left-value">
                                <div className="wrap-value">
                                    <div className="value">
                                        <div>{specificYieldToday}</div>
                                    </div>
                                    <div className="unit">
                                        <div>kWh/kWp</div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} xl={12} className="right-value">
                                <div className="group-item">
                                    <div className="label">
                                        Yesterday
                                    </div>
                                    <div className="value">
                                        {specificYieldYesterday}
                                    </div>
                                </div>
                                <div className="group-item">
                                    <div className="label">
                                        Month to Date
                                    </div>
                                    <div className="value">
                                        {specificYieldMonthToDate}
                                    </div>
                                </div>
                                <div className="group-item">
                                    <div className="label">
                                        Year to Date
                                    </div>
                                    <div className="value">
                                        {specificYieldYearToDate}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Divider className="divider"/>
                    <div className="group-gauges group-yield">
                        <div className="group-title">
                            <BarChartOutlined/> Performance Ratio
                        </div>
                        <Row className="group-value">
                            <Col xs={12} xl={12} className="left-value">
                                <div className="wrap-value">
                                    <div className="value">
                                        <div>{performanceRatioToday}</div>
                                    </div>
                                    <div className="unit">
                                        <div>%</div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} xl={12} className="right-value">
                                <div className="group-item">
                                    <div className="label">
                                        Yesterday
                                    </div>
                                    <div className="value">
                                        {performanceRatioYesterday}
                                    </div>
                                </div>
                                <div className="group-item">
                                    <div className="label">
                                        Month to Date
                                    </div>
                                    <div className="value">
                                        {performanceRatioMonthToDate}
                                    </div>
                                </div>
                                <div className="group-item">
                                    <div className="label">
                                        Year to Date
                                    </div>
                                    <div className="value">
                                        {performanceRatioYearToDate}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </AntCard>
        )
    }

}

function mapStateToProps(state) {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);