import React, { Component } from 'react';
import { connect } from "react-redux";
import { AntButton, AntCard, Loading, ToolboxControl } from "@layouts";
import { FileOutlined, BoxPlotOutlined, HddOutlined, ReconciliationOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { withRouter } from "react-router-dom";
import { REPORT_TYPE } from "@ultis/constants";

class CustomComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            selectedId: REPORT_TYPE.PLANT_REPORT,
        }
    }

    /**
     * On change input
     * @param e
     */
    onChange = (e) => {
        const searchText = e.currentTarget.value;
        setTimeout(() => {
            this.setState({
                ...this.state,
                searchText: searchText
            })
        })
    };

    /**
     * Render search list
     * @param reportList
     * @returns {*[]}
     */
    renderReportList = (reportList = []) => {
        let items = [];
        reportList.map(item => {
            return items.push({
                "id": item.id,
                "reportType": item.reportType,
                "title": item.name
            },)
        })
        return [
            {
                "title": "General",
                "reports": items
            },
            {
                "title": "Performance",
                "reports": []
            },
            {
                "title": "Environment",
                "reports": []
            },
            {
                "title": "Operation & Maintenance",
                "reports": []
            },
        ]
    }

    /**
     * Render search list
     * @param searchList
     * @returns {*[]}
     */
    renderSearchList = (searchList) => {
        let { searchText } = this.state
        let newMatch = [];
        // Find in mockup
        searchList.forEach((group, groupIdx) => {
            let isDisplay = false;
            let reports = [];

            // Render new list
            group.reports.forEach((report) => {
                if (report.title.toString().toLowerCase().indexOf(searchText.toString().toLowerCase()) !== -1) {
                    reports.push({
                        ...report
                    })

                    isDisplay = true;
                }
            })

            newMatch.push({
                "title": group.title,
                "reports": reports,
                "isDisplay": isDisplay,
                "no": groupIdx + 1,
            })
        })

        return newMatch
    }

    onClick = (e) => {
        const value = e.currentTarget.value;
        this.setState({
            ...this.state,
            selectedId: value
        })

        this.props.onChangeReportType(value, e.currentTarget.dataset['reportType'])
    };

    render() {
        const { common } = this.props

        const parentElementId = "report-list";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

        const { searchText, selectedId } = this.state

        const { loading, data } = this.props.report.list
        const reportList = this.renderReportList(data)
        const searchList = this.renderSearchList(reportList)

        const iconList = {
            "Plant Report": <HddOutlined/>,
            "Device Report": <BoxPlotOutlined/>,
            "Device Detail Report": <ReconciliationOutlined/>
        };

        return (
            <AntCard
                id={parentElementId}
                className={"report-list card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <FileOutlined/> Report
                        </div>
                        <ToolboxControl parentElementId={parentElementId}/>
                    </div>
                }
            >
                <div className="card-custom-body">
                    <div>
                        <div className="search-box">
                            <Input
                                className="search-input"
                                placeholder="Search report"
                                value={searchText}
                                onChange={this.onChange}
                                allowClear={true}
                                style={{
                                    width: "100%",
                                    borderRadius: 3
                                }}
                            />
                        </div>
                        <div className="search-group-result">
                            {
                                loading ? <Loading/> :
                                    (
                                        searchList.map((group, idx) => {
                                            return (
                                                (group.isDisplay) ? (
                                                    <div className="group-result" key={idx}>
                                                        <div className="group-title">
                                                            {group.no + '. ' + group.title}
                                                        </div>
                                                        <div className="list-data">
                                                            {
                                                                group.reports.map((item, idxItem) => {
                                                                    return (
                                                                        <ListItem
                                                                            icon={iconList}
                                                                            key={idxItem}
                                                                            selectedId={selectedId}
                                                                            item={item}
                                                                            onClick={this.onClick}
                                                                        />
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                ) : null
                                            )
                                        })
                                    )
                            }
                        </div>
                    </div>
                </div>
            </AntCard>
        )
    }
}

const ListItem = ({ item, selectedId, onClick, icon }) => {
    let classItem = 'data-item';

    if (selectedId === item.id.toString()) {
        classItem += ' selected'
    }

    return (
        <div className={classItem}>
            <AntButton
                type="link"
                value={item.id}
                onClick={onClick}
                data-report-type={item.reportType}
            >
                {icon[item.title]} {item.title}
            </AntButton>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        common: state.common,
        report: state.report,
    }
}

export default withRouter(connect(mapStateToProps, {})(CustomComponent))