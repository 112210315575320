import initialState from "./initialState";
import * as CONSTANTS from "./constants";
import * as CONSTANTS_COMMON from "@features/Common/redux/constants";

export function reducer(state = initialState, action) {
    let payload = action.payload;
    switch (action.type) {
        /**
         * Reset action
         */
        case CONSTANTS_COMMON.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        case CONSTANTS.ANALYSIS_LIST_LOADING:
            return {
                ...state,
                listByPlant: {
                    ...state.listByPlant,
                    loading: true
                },
                listBySite: {
                    ...state.listBySite,
                    loading: true
                }
            };
        case CONSTANTS.ANALYSIS_LIST_BY_SITE:
            return {
                ...state,
                listBySite: {
                    ...state.listBySite,
                    loading: false,
                    data: payload ?? [],
                }
            };
        case CONSTANTS.ANALYSIS_LIST_BY_PLANT:
            return {
                ...state,
                listByPlant: {
                    ...state.listByPlant,
                    loading: false,
                    data: payload ?? [],
                }
            };
        default:
            return state;
    }
}