/**
 * Define permission
 * @type {{TECHNICAL_MONITORING: string, ASSETS_PERFORMANCE: string, BILLING: string, BILLING_CONFIGURATIONS: string, ANALYSIS: string, MONITORING_RULES: string, REPORT: string, TICKETS_MANAGEMENT: string, ASSET_MANAGEMENT: string}}
 */
export const PERMISSIONS = {
    TECHNICAL_MONITORING: "technical-monitoring",
    MINI_SCADA: "mini-scada",
    ASSETS_PERFORMANCE: "asset-performance",
    ANALYSIS: "analysis",
    REPORT: "report",
    ASSET_MANAGEMENT: "asset-management",
    BILLING: "billing",
    TICKETS_MANAGEMENT: "tickets-management",
    MONITORING_RULES: "monitoring-rules",
    BILLING_CONFIGURATIONS: "billing-configurations",
    PORTFOLIO_CONFIGURATIONS: "portfolio-configurations",
};