// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.

const initialState = {
    currentBilling: {
        loading: false,
        data: {},
    },

    costTrend: {
        loading: false,
        options: {
            groupBy: "",
            series: [],
        },
    },

    invoiceList: {
        loading: false,
        data: [],
    },

    invoiceDetail: {
        loading: false,
        data: {},
    }
};

export default initialState;
