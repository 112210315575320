import React, { Component } from 'react';
import { Col, Row, Skeleton } from "antd";
import * as PlantComponents from "../../components";

class President extends Component {
    constructor(props) {
        super(props)
        this.focusRef = React.createRef()
    }

    /**
     * Scroll to ticket list
     * @param date
     */
    onSelectCellCalendar = (date) => {
        this.focusRef.current.scrollIntoView({
            behavior: 'smooth'
        })
        this.props.getPlantTicketsByDate(date);
    }

    render() {
        const {
            detail,
            meta,

            assignGroups, // All assign group
            selectedRoles,// Selected assign group
            onSelectedRoles, // On selected assign group

            ticketsDue, // Tickets dua on calendar

            ticketParams, // Ticket query params
            onChangeKeyword,
            onChangeDueOn,
            onChangeTicketStatus,
            onChangeDeviceType,
            onChangeTableTickets,

            isVisibleTicket, // Show or Hide modal ticket
            onClickNewTicket, // On click new ticket
            onClickEditTicket, // On click edit ticket
            onCloseTicket, // On click hide ticket
            onSubmitTicket, // On submit form ticket

            currentUser, // Current login user
            ticket, // Current ticket detail

            listSLA, // Master data
            listEquipment,
            listStatus,
        } = this.props

        let {
            errors,
            createLoading,
            updateLoading,
            deleteLoading,
            onShowConfirmDelete,
            isDetail
        } = this.props

        // Render data
        const { data, loading } = detail

        const formListPlants = [
            {
                id: data.id,
                name: "CTY TNHH CK DL TOÀN CẦU" + " > " + data.name,
            }
        ];
        return (
            <div className="features feature-plant">
                <h1 className="page-title" id="test">
                    {loading ? <Skeleton.Input active={loading}/> : data.name}
                </h1>
                <Row gutter={{ xs: 8, sm: 12, md: 12 }}>
                    <Col xs={24} xl={18}>
                        <PlantComponents.PlantTicketDue
                            meta={meta}
                            selectedRoles={selectedRoles}
                            ticketsDue={ticketsDue}
                            onSelectCellCalendar={this.onSelectCellCalendar}
                        />
                    </Col>
                    <Col xs={24} xl={6}>
                        <PlantComponents.PersonInCharge
                            meta={meta}
                            selectedRoles={selectedRoles}
                            onSelectedRoles={onSelectedRoles}
                            assignGroups={assignGroups}
                        />
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 12, md: 12 }} ref={this.focusRef}>
                    <Col xs={24}>
                        <PlantComponents.PlantTickets
                            meta={meta}
                            ticketParams={ticketParams}
                            onChangeKeyword={onChangeKeyword}
                            onChangeDueOn={onChangeDueOn}
                            onChangeTicketStatus={onChangeTicketStatus}
                            onChangeDeviceType={onChangeDeviceType}
                            onChangeTableTickets={onChangeTableTickets}

                            // New or Edit ticket
                            onClickNewTicket={onClickNewTicket}
                            onClickEditTicket={onClickEditTicket}
                        />
                    </Col>
                </Row>
                <PlantComponents.FormDetailTicket
                    isVisibleTicket={isVisibleTicket}
                    onClickNewTicket={onClickNewTicket}
                    onClickEditTicket={onClickEditTicket}
                    onCloseTicket={onCloseTicket}
                    onSubmitTicket={onSubmitTicket}

                    currentUser={currentUser}
                    listPlant={formListPlants}
                    listSLA={listSLA}
                    listEquipment={listEquipment}
                    listStatus={listStatus}
                    listUserGroup={assignGroups}

                    ticket={ticket} // Current ticket detail
                />
            </div>

        )
    }
}

export default President;