// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.

const initialState = {
    listByPlant: {
        loading: false,
        data: [],
    },

    listBySite: {
        loading: false,
        data: [],
    },

    detail: {
        loading: false,
        data: {},
        id: null,
        isFound: true,
    },
};

export default initialState;
