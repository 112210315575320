import React, { Component } from 'react';
import { connect } from "react-redux";
import { AntCard, TagInverterStatus, ToolboxControl } from "@layouts";
import { UnorderedListOutlined, ImportOutlined, InsertRowBelowOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Table } from 'antd';
import { findLocation } from "../../redux";
import { fetchTableEvent } from "@features/Event/redux";
import { getPlantDataOverview } from "@features/Plant/redux";

class CustomComponent extends Component {
	handleClickPlant = (e) => {
		const siteId = e.currentTarget.dataset.siteId
		const plantId = e.currentTarget.dataset.plantId
		const { plants, zoom } = this.props.overview

		let coordinatesList = []
		plants.forEach(item => {
			if (item.id.toString() === plantId.toString()) {
				coordinatesList.push(item)
			}
		})

		this.props.findLocation(coordinatesList, zoom)
		this.props.fetchTableEvent({
			siteId: siteId,
			plantId: plantId,
		})
	}

	handleClickSite = (e) => {
		const siteId = e.currentTarget.dataset.siteId
		const { plants, zoom } = this.props.overview
		const { plantOverview } = this.props.plant;
		const dataPlant = plantOverview.data;

		let l = 0, r = dataPlant.length - 1, mid, res = [], pos = -1;
		while (l <= r) {
			mid = (r + l) >> 1;
			const findId = dataPlant[mid].site._id;
			if (findId >= siteId) {
				pos = mid;
				r = mid - 1;
			}
			else {
				l = mid + 1;
			}
		}

		if (pos !== -1) {
			if (pos === dataPlant.length - 1) {
				res.push(dataPlant[pos]);
			}
			else {
				while (dataPlant[pos].site._id === siteId) {
					res.push(dataPlant[pos]);
					++pos;
				}
			}
		}

		this.props.getPlantDataOverview({
			type: 'findMatch',
			siteId: siteId,
			sitePayload: res,
		})

		let coordinatesList = []
		plants.forEach(item => {
			if (item.siteId.toString() === siteId.toString()) {
				coordinatesList.push(item)
			}
		})
		this.props.findLocation(coordinatesList, zoom)
		this.props.fetchTableEvent({
			siteId: siteId,
		})


	}

	render() {
		const {
			common,
			fetchPlants,
			isDisplaySite,
			overview
		} = this.props

		const { plantOverview } = this.props.plant;

		const parentElementId = "site-plant-list";
		const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

		const {
			loading,
			total,
			powerValue,
			powerUnit,
			plants
		} = overview

		return (
			<AntCard
				id={parentElementId}
				className={"card-custom " + fullScreenClass}
				title={
					<div className="card-custom-title">
						<div className="card-information">
							<UnorderedListOutlined/> Plants
							<i><span> - {total} Plants - {powerValue} {powerUnit}</span></i>
						</div>
						<ToolboxControl
							parentElementId={parentElementId}
							handleReload={fetchPlants}
						/>
					</div>
				}
			>
				<div className="card-custom-body full-width full-height height-350">
					<Table
						loading={loading}
						columns={this.renderColumns(isDisplaySite, plantOverview.data)}
						dataSource={plants}
						pagination={false}
						rowKey="id"
					>
					</Table>
				</div>
			</AntCard>
		)
	}

	renderColumns = (isDisplaySite, overviewPlantData) => {
		let baseColumns = [
			{
				ellipsis: true,
				title: "Status",
				dataIndex: "status",
				key: "status",
				render: (value) => <TagInverterStatus level={value}/>,
				align: "center",
				width: 75,
			},
			{
				title: 'Plant',
				dataIndex: 'name',
				render: (text, item) =>
					<div>
						<InsertRowBelowOutlined/>
						<span
							className="highlight"
							data-domain-id={"1"}
							data-site-id={item.siteId}
							data-plant-id={item.id}
							onClick={this.handleClickPlant}> {text}</span>
						<Link to={`/technical-monitoring/plant/${item.id}`}> <ImportOutlined className="transform-180"/></Link>
					</div>,
				width: 200,
			},
			{
				ellipsis: true,
				title: 'Capacity (kW)',
				dataIndex: 'powerValue',
				key: 'powerValue',
				width: 90,
				align: "center"
			},
			{
				ellipsis: true,
				title: 'Commissioning on',
				dataIndex: 'commissioningOn',
				key: 'commissioningOn',
				width: 130,
				align: "center"
			},
		];

		if (isDisplaySite) {
			baseColumns.push(
				{
					title: 'Site',
					dataIndex: 'siteName',
					render: (text, item) =>
						<div>
                            <span
	                            // className="highlight"
	                            className={overviewPlantData.length > 1 ? "highlight" : "custom-blocked"}
	                            data-domain-id={"1"}
	                            data-site-id={item.siteId}
	                            onClick={this.handleClickSite}
                            >
                                {text}
                            </span>
							<Link to={`/technical-monitoring/site/${item.siteId}`}> <ImportOutlined
								// className={"transform-180"}
								className={overviewPlantData.length > 1 ? "transform-180" : "custom-blocked transform-180"}
							/></Link>
						</div>,
					/*sorter   : {
						compare : (a, b) => a.siteName.toString().localeCompare(b.siteName.toString()),
						multiple: 3,
					},*/
				},
			)
		}
		return baseColumns
	}
}

function mapDispatchToProps(dispatch) {
	return {
		findLocation: (coordinatesList, zoom) => {
			dispatch(findLocation(coordinatesList, zoom));
		},
		fetchTableEvent: (params) => {
			dispatch(fetchTableEvent(params));
		},
		getPlantDataOverview: params => {
			dispatch(getPlantDataOverview(params));
		}
	};
}

function mapStateToProps(state) {
	return {
		common: state.common,
		plant: state.plant,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomComponent);