import * as CONSTANTS from './constants'
import { apiGet } from "@common/crud";

export function getDeviceList(plantId) {
    return dispatch => {
        dispatch(getDeviceListActionLoading())
        dispatch(apiGet('devices', {
            "plantId": plantId ?? ""
        }, {}, getDeviceListAction))
    };
}

function getDeviceListAction(response) {
    return {
        type: CONSTANTS.GET_DEVICE_LIST,
        payload: response
    };
}

function getDeviceListActionLoading() {
    return {
        type: CONSTANTS.GET_DEVICE_LIST_LOADING,
        payload: null
    };
}