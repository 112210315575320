import React, { Component } from 'react';
import { Row, Col } from 'antd';
import * as Components from "../../components";

//import { withNamespaces } from 'react-i18next';

class President extends Component {
    render() {
        //const {t} = this.props;
        const { overview } = this.props.technicalMonitoring;
        const { getPlantDataOverview, showReload } = this.props;

        return (
            <div className="features feature-home">
                <h1 className="page-title">
                    ENTIRE DOMAIN
                </h1>
                <Row gutter={{ xs: 8, sm: 12, md: 12 }}>
                    <Col xs={24} xl={15}>
                        <Components.PlantList
                            overview={overview}
                            isDisplaySite={true}
                        />
                    </Col>
                    <Col xs={24} xl={9}>
                        <Components.GeolocationView
                            overview={overview}
                            onChangeResizeMap={this.props.onChangeResizeMap}
                        />
                    </Col>
                </Row>
                <div>
                    <Components.PlantOverview
                        showReload={showReload}
                        refreshPlantData={() => getPlantDataOverview({
                            type: 'refresh',
                            siteId: null,
                        })}
                    />
                </div>
                <div>
                    <Components.EventList/>
                </div>
            </div>
        );
    }
}

//export default withNamespaces(['common'])(President);

export default President;