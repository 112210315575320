import React, { Component } from "react";
import { Link } from "react-router-dom";
import background from "../../images/auth/background.jpg";
import logo from "../../images/logo.png";
import imageForm from "../../images/formimage.jpg";
import { Button, Form, Input } from "antd";
import FormItem from "antd/es/form/FormItem";

class President extends Component {
    onFinish = values => {
        console.log('Success:', values);
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    render() {
        return (
            // <div className="auth" style={{ backgroundImage: `url(${background})` }}>
            //     <div className="antd-pro-layouts-user-layout-content">
            //         <div className="antd-pro-layouts-user-layout-top">
            //             <div className="antd-pro-layouts-user-layout-header">
            //                 <Link to="/">
            //                     <img alt="" className="antd-pro-layouts-user-layout-logo" src={logo}/>
            //                 </Link>
            //             </div>
            //             <div className="antd-pro-layouts-user-layout-desc">
            //                 Monitoring & Control
            //             </div>
            //         </div>
            //         <div className="antd-pro-pages-user-login-index-main">
            //             {this.props.children}
            //         </div>
            //     </div>
            // </div>
            <div>
                {this.props.children}
            </div>
        );
    }
}

export default President