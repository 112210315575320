import React, { Component } from 'react';
import { connect } from "react-redux";
import { DatePicker, Space } from 'antd';
import { AntButton } from "@layouts";
import { CaretLeftOutlined, CaretRightOutlined, } from "@ant-design/icons";

const { RangePicker } = DatePicker;

class CustomComponent extends Component {
    onCalendarChange = (dates) => {
        let from = dates ? dates[0] : null;
        let to = dates ? dates[1] : null;

        this.props.setDetailParams({
            from: from,
            to: to,
        });
    };

    onClickControl = (e) => {
        let value = e.currentTarget.value;
        let { from, to, diffDays } = this.getCurrentRange()
        let newFrom = from
        let newTo = to
        switch (value) {
            case 'next':
                newFrom = from ? from.add(diffDays, 'days') : null
                newTo = to ? to.add(diffDays, 'days') : null
                break;
            case 'previous':
                newFrom = from ? from.subtract(diffDays, 'days') : null
                newTo = to ? to.subtract(diffDays, 'days') : null
                break;
            default:
                break;
        }

        this.props.setDetailParams({
            from: newFrom,
            to: newTo,
        });
    }

    getCurrentRange = () => {
        let { from, to } = this.props
        let diffDays = to ? to.diff(from, 'days') : 0
        diffDays = diffDays === 0 ? 1 : diffDays

        return {
            from: from ? from.clone() : null,
            to: to ? to.clone() : null,
            diffDays: diffDays
        }
    }

    render() {
        const { ranges, from, to } = this.props;

        return (
            <Space direction="horizontal" size={12}>
                <div className="quick-control">
                    <AntButton
                        icon={<CaretLeftOutlined/>}
                        value={"previous"}
                        onClick={this.onClickControl}
                    />
                    <AntButton
                        icon={<CaretRightOutlined/>}
                        value={"next"}
                        onClick={this.onClickControl}/>
                </div>
                <RangePicker
                    value={[from, to]}
                    ranges={ranges}
                    format="YYYY-MM-DD HH:mm:ss"
                    onCalendarChange={this.onCalendarChange}
                />
            </Space>
        )
    }
}

function mapStateToProps(state) {
    return {
        common: state.common,
        analysis: state.analysis,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);