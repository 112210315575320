import initialState from "./initialState";
import * as CONSTANTS from './constants'
import * as CONSTANTS_COMMON from "@features/Common/redux/constants";

export function reducer(state = initialState, action) {
    let payload = action.payload;

    switch (action.type) {
        /**
         * Reset action
         */
        case CONSTANTS_COMMON.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        /**
         * Site logic
         */
        case CONSTANTS.SITE_DETAIL:
            payload = payload ?? {}
            let detailSite = payload.data ?? {}
            return {
                ...state,
                detailSite: {
                    ...state.detailSite,
                    loading: false,
                    errors: detailSite.errors ?? [],
                    data: detailSite,
                    id: detailSite.id,
                    isFound: !!detailSite.id
                },
            };
        case CONSTANTS.SITE_DETAIL_LOADING:
            return {
                ...state,
                detailSite: {
                    ...state.detailSite,
                    loading: true,
                }
            }

        default:
            return state;
    }
}
