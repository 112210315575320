import initialState from "./initialState";
import * as CONSTANTS from "./constants";
import { LOCATION_CHANGE } from "connected-react-router";
import * as CONSTANTS_COMMON from "@features/Common/redux/constants";

export function reducer(state = initialState, action) {
    switch (action.type) {
        case LOCATION_CHANGE:
            return {
                ...state,
            };
        /**
         * Reset action
         */
        case CONSTANTS_COMMON.RESET_ACTION:
            return {
                ...state,
                ...initialState
            }

        default:
            return state;
    }
}