import React, { Component } from 'react';
import { ToolboxControl } from "@layouts";
import { connect } from "react-redux";
import { FieldTimeOutlined, HddOutlined } from "@ant-design/icons";
import { AntCard, Loading } from "@layouts";
import { Col, Divider, Row, Table } from "antd";

class CustomComponent extends Component {
	render() {
		const { common, meta, detail, handleChangeInverter, defaultDeviceCode } = this.props

		const parentElementId = "plant-real-time-device";
		const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

		// Init chart data
		// const { loading, options } = meta.stringMonitoring

		const { inverters } = detail.data;
		let inverterList = inverters ?? [];
		const { data, loading } = meta.inverterDetail;
		const { tableData, formData, lastTime } = data;

		return (
			<AntCard
				id={parentElementId}
				className={"card-custom " + fullScreenClass}
				title={
					<div className="card-custom-title">
						<div className="card-information">
							<FieldTimeOutlined/> RealTime Device Data
						</div>
						<span className="toolbox-control select-time-control">
                        <ToolboxControl
	                        updateAt={lastTime}
	                        disabled={!inverterList.length}
	                        parentElementId={parentElementId}
	                        selectInverterOptions={inverterList}
	                        handleChangeInverter={handleChangeInverter}
	                        defaultDeviceCode={defaultDeviceCode}
                        />
                        </span>
					</div>
				}
			>
				<div className="card-custom-body full-width full-height">
					{loading ? <Loading overwrite/> : null}
					<Table
						columns={tableData && tableData.length > 0 ? renderColumn() : []}
						dataSource={tableData ?? []}
						pagination={false}
						loading={false}
						rowKey={"string"}
						scroll={{ x: 1000 }}
					>
					</Table>
					<Divider orientation="left"></Divider>
					<div className={"form-realtime-device-data"}>
						{
							formData &&
							<>
								<h3><HddOutlined/> Inverter Realtime Data Detail</h3>
								{
									renderForm(formData)
								}
							</>
						}
					</div>
				</div>
			</AntCard>
		)
	}
}

const paramList = formData => {
	return [
		[
			{ title: 'Inverter Status', value: formData.inverterStatus },
			{ title: 'Active power', value: formData.activePower },
			{ title: 'Power factor', value: formData.powerFactor },
			{ title: 'Grid phase A current', value: formData.gridPhaseACurrent },
			{ title: 'Grid AB line voltage', value: formData.gridABLineVoltage },
			{ title: 'Inverter startup time', value: formData.inverterStartupTime },
			{ title: 'Insulation resistance', value: formData.insulationResistance },
		],
		[
			{ title: 'Daily energy', value: formData.dailyEnergy },
			{ title: 'Output reactive power', value: formData.outputReactivePower },
			{ title: 'Grid frequency', value: formData.gridFrequency },
			{ title: 'Grid phase B current', value: formData.gridPhaseBCurrent },
			{ title: 'Grid BC line Voltage', value: formData.gridBCLineVoltage },
			{ title: 'Inverter shutdown time', value: formData.inverterShutdownTime },
		],
		[
			{ title: 'Cumulative energy', value: formData.cumulativeEnergy },
			{ title: 'Inverter rated power', value: formData.inverterRatedPower },
			{ title: 'Output mode', value: formData.outputMode },
			{ title: 'Grid phase C current', value: formData.gridPhaseCCurrent },
			{ title: 'Grid CA line voltage', value: formData.gridCALineVoltage },
			{ title: 'Internal temperature', value: formData.internalTemperature },
		],
	]
}

const renderForm = formData => {
	const data = paramList(formData);
	return (
		<Row gutter={{ xs: 16, sm: 24, md: 32, lg: 40, }}>
			{
				data.map((element, index) =>
					<Col className="gutter-row" span={8} key={index}>
						{
							element.map(item => <div key={item.title}>{item.title} <b>{item.value}</b></div>)
						}
					</Col>
				)
			}
		</Row>
	)
}

function mapStateToProps(state) {
	return {
		common: state.common,
		plant: state.plant,
	}
}

export default connect(mapStateToProps, {})(CustomComponent);

const renderColumn = () => {
	let column = [
		{
			title: 'String',
			dataIndex: 'string',
			key: 'string',
			width: 150,
			ellipsis: true,
		},
	]
	for (let i = 1; i <= 20; ++i) {
		column.push({
			title: `PV${i}`,
			dataIndex: `PV${i}`,
			key: `PV${i}`,
			width: 80,
			ellipsis: true,
		})
	}
	return column;
}