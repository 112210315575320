import React, { Component } from 'react';
import { ToolboxControl } from "@layouts";
import { connect } from "react-redux";

import { AntCard, DataEmpty, Loading, MapComponent } from "@layouts";
import { CompassFilled } from "@ant-design/icons";

class CustomComponent extends Component {
    render() {
        const { common, onChangeResizeMap, overview } = this.props
        const parentElementId = "site-geolocation";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

        let { coordinatesList, zoom, loading } = overview
        const isLoaded = coordinatesList.length > 0

        // Calculate center view
        let latCenter = 0;
        let lngCenter = 0;
        let latSum = 0;
        let lngSum = 0;
        let total = 0;
        if (isLoaded) {
            coordinatesList.forEach(item => {
                const coordinates = item.coordinates
                total++;
                latSum += coordinates.lat
                lngSum += coordinates.lng
            })

            latCenter = latSum / total
            lngCenter = lngSum / total
        }

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <CompassFilled/> Geolocation
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            onChangeResize={onChangeResizeMap}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-width full-height">
                    {
                        loading ? <Loading/> : isLoaded ? <MapComponent
                            coordinatesList={coordinatesList}
                            latCenter={latCenter}
                            lngCenter={lngCenter}
                            zoom={zoom}
                        /> : <DataEmpty title="No plant selected."/>
                    }
                </div>
            </AntCard>
        )
    }
}

function mapStateToProps(state) {
    return {
        common: state.common,
    }
}

export default connect(mapStateToProps, {})(CustomComponent)