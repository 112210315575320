import * as CONSTANTS from "./constants";
import { apiGet, apiPostForm, CODE_SUCCESS } from "@common/crud";
import { pushMessageSuccess } from "@layouts";

export function getPortfolioDetail(id) {
    return dispatch => {
        dispatch(portfolioDetailLoadingAction())
        dispatch(apiGet('portfolio/' + id, {}, {}, portfolioDetailAction))
    };
}

export function portfolioDetailAction(response) {
    return {
        type: CONSTANTS.PORTFOLIO_DETAIL,
        payload: response
    };
}

export function portfolioDetailLoadingAction() {
    return {
        type: CONSTANTS.PORTFOLIO_DETAIL_LOADING,
        payload: null
    };
}

export function updatePortfolio(id, params) {
    const { name, logo } = params

    let newParams = {
        "name": name,
    }
    if (logo !== undefined) {
        newParams["logo"] = logo
    }
    return dispatch => {
        dispatch(portfolioUpdateLoadingAction())
        dispatch(apiPostForm('portfolio/' + id, newParams, {}, portfolioUpdateAction))
    }
}

export function portfolioUpdateAction(response) {
    if (response.code === CODE_SUCCESS) {
        pushMessageSuccess();
    }
    return {
        type: CONSTANTS.PORTFOLIO_UPDATE,
        payload: response
    };
}

export function portfolioUpdateLoadingAction() {
    return {
        type: CONSTANTS.PORTFOLIO_UPDATE_LOADING,
        payload: null
    };
}