import React, { Component } from 'react';
import { Col, Row, Skeleton } from "antd";
import * as PlantComponents from "../../components";

class President extends Component {
    render() {
        const {
            handleChangeSpecificYield,
            handleChangeDailyIODiagram,
            handleChangePerformanceRatioTracking,
            handleChangeActualProductionVsBudgetProduction,
            handleChangeCarbonOffset,
            detail,
            meta,
        } = this.props

        // Render data
        const { data, loading } = detail
        return (
            <div className="features feature-plant">
                <h1 className="page-title">
                    {loading ? <Skeleton.Input active={loading}/> : data.name}
                </h1>
                <Row gutter={{ xs: 8, sm: 12 }}>
                    <Col xs={24} xl={15}>
                        <PlantComponents.PlantPerformanceRatioTracking
                            meta={meta}
                            handleChangeTime={handleChangePerformanceRatioTracking}
                        />
                    </Col>
                    <Col xs={24} xl={9}>
                        <PlantComponents.PlantGauges
                            meta={meta}
                        />
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 12 }}>
                    <Col xs={24} xl={12}>
                        <PlantComponents.PlantActualProductionVsBudgetProduction
                            meta={meta}
                            handleChangeTime={handleChangeActualProductionVsBudgetProduction}
                        />
                    </Col>
                    <Col xs={24} xl={12}>
                        <PlantComponents.PlantCarbonOffset
                            meta={meta}
                            handleChangeTime={handleChangeCarbonOffset}
                        />
                    </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 12 }}>
                    <Col xs={24} xl={12}>
                        <PlantComponents.PlantSpecificYield
                            meta={meta}
                            handleChangeTime={handleChangeSpecificYield}
                        />
                    </Col>
                    <Col xs={24} xl={12}>
                        <PlantComponents.PlantChartDailyIODiagram
                            meta={meta}
                            handleChangeTime={handleChangeDailyIODiagram}
                        />
                    </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 12, md: 12 }}>
                    <Col xs={24}>
                        <PlantComponents.PlantInverterPerformance
                            meta={meta}
                        />
                    </Col>
                </Row>
            </div>

        )
    }
}

export default President;