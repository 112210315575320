import {
    CalendarOutlined,
    DotChartOutlined,
    GlobalOutlined,
    HeatMapOutlined,
    LineChartOutlined,
    PercentageOutlined,
    PieChartOutlined,
    RiseOutlined
} from "@ant-design/icons";

export const iconList = {
    "Actual Production & Actual Irradiation": <PercentageOutlined/>,
    "String Monitoring": <HeatMapOutlined/>,
    "I-V Curve": <DotChartOutlined/>,
    "Performance Ratio Tracking": <PieChartOutlined/>,
    "Actual Production vs Budget Production": <RiseOutlined/>,
    "Carbon offset": <GlobalOutlined/>,
    "Specific Yield": <LineChartOutlined/>,
    "Daily IO Diagram": <CalendarOutlined/>
}
