import React, { Component } from 'react';
import { Form, Input, message } from "antd";
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { AntButton, AntFormItem, } from "@layouts";
import ReCAPTCHA from "react-google-recaptcha";
import imageForm from "@images/formimage.jpg";
import logo from "@images/logo.png";
import { Link } from "react-router-dom";

class President extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCaptchaSelected: false,
        }
    }

    handleCaptchaChange = () => {
        this.setState({
            ...this.state,
            isCaptchaSelected: true,
        })
    }

    handleRequest = data => {
        const { email } = data;
        const { isCaptchaSelected } = this.state;
        let requestResult = 'Check information again', type = 'error';

        switch (true) {
            case !email && !isCaptchaSelected:
                requestResult = 'Please fill email and check captcha!!!';
                break;
            case email && !isCaptchaSelected:
                requestResult = 'Please check captcha!!!';
                break;
            case !email && isCaptchaSelected:
                requestResult = 'Please fill your email!!!';
                break;
            case email && isCaptchaSelected:
                requestResult = 'Success, please check request in email!!!';
                type = 'success';
                break;
            default:
                break;
        }

        return message.open({
            content: requestResult,
            type: type,
        });
    }

    render() {
        const { errors } = this.props.crud;

        return (
            <div className="login-page">
                <div className="login-box">
                    <div className="illustration-wrapper">
                        <img
                            src={imageForm}
                            alt="Login"/>
                    </div>
                    <div>
                        <Form
                            name="login-form"
                            onFinish={this.handleRequest}
                        >
                            <div className={"login-title"}>
                                <img src={logo} alt="" className={"customLogo"}/>
                                <p className={'customInfo'}>Monitoring & control</p>
                            </div>
                            <AntFormItem
                                errors={errors.username}
                                name="email"
                            >
                                <Input
                                    prefix={<MailOutlined className="site-form-item-icon"/>}
                                    placeholder="Email"
                                />
                            </AntFormItem>

                            <AntFormItem
                                errors={errors.username}
                                name="captcha"
                            >
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                                    onChange={this.handleCaptchaChange}
                                />
                            </AntFormItem>

                            <AntButton
                                className="btn-main-primary"
                                type="primary"
                                htmlType="submit"
                                block={true}
                                loading={this.props.loading}
                            >
                                Request new password
                            </AntButton>

                            <div className="form-option">
                                <Link to={'/login'}>Back to login page</Link>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

export default President;