import React, { Component } from 'react';
import { DesktopOutlined } from "@ant-design/icons";
import { AntCard, Loading, ToolboxControl } from "@layouts";
import { connect } from "react-redux";
import * as Plant from "@features/Plant";

class CustomComponent extends Component {
	render() {
		const { common } = this.props
		const parentElementId = "plant-cost-trend"; // unique key with quarter billing
		const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

		const { handleChangeTime, billing, } = this.props

		const { costTrend: { loading, options } } = billing;

		return (
			<AntCard
				id={parentElementId}
				className={"card-custom " + fullScreenClass}
				title={
					<div className="card-custom-title">
						<div className="card-information">
							<DesktopOutlined/> Cost trend
						</div>
						<ToolboxControl
							parentElementId={parentElementId}
							selectTimeDefault="last_12_months"
							selectTimeOptions={[
								{ title: "Last 12 months", value: "last_12_months" },
								{ title: "This year", value: "this_year" },
							]}
							handleChangeTime={handleChangeTime}
						/>
					</div>
				}
			>
				<div className="card-custom-body full-height full-width">
					{loading ? <Loading overwrite/> : null}
					<Plant.ChartCostTrend options={options}/>
				</div>
			</AntCard>
		)
	}
}

function mapStateToProps(state) {
	return {
		billing: state.billing,
		common: state.common,
	}
}

export default connect(mapStateToProps, {})(CustomComponent);