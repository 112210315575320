import React, { Component } from 'react';
import { ToolboxControl } from "@layouts";
import { connect } from "react-redux";
import { AreaChartOutlined } from "@ant-design/icons";
import { AntCard, Loading } from "@layouts";
import * as Plant from "@features/Plant";

class CustomComponent extends Component {
	render() {
		const { common, meta, handleChangeTime, onChangeResizeChart } = this.props;

		const parentElementId = "plant-actual";
		const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

		// Init chart data
		const { loading, options } = meta.actualProductionIrradiation;


		return (
			<AntCard
				id={parentElementId}
				className={"card-custom " + fullScreenClass}
				title={
					<div className="card-custom-title">
						<div className="card-information">
							<AreaChartOutlined/> Actual Production & Actual Irradiation
						</div>
						<ToolboxControl
							parentElementId={parentElementId}
							selectTimeOptions={[
								{ title: "Last 24 hours", value: "last_24_hour" },
								{ title: "Today", value: "today" },
								{ title: "Yesterday", value: "yesterday" },
								{ title: "Last 7 days", value: "last_7_days" },
								{ title: "This week", value: "this_week" },
								{ title: "Last 30 days", value: "last_30_days" },
								{ title: "This month", value: "this_month" },
								{ title: "Last 6 months", value: "last_6_months" },
								{ title: "Last 12 months", value: "last_12_months" },
								{ title: "This year", value: "this_year" },
							]}
							handleChangeTime={handleChangeTime}
							onChangeResize={onChangeResizeChart}
						/>
					</div>
				}
			>
				<div className="card-custom-body full-width">
					{loading ? <Loading overwrite/> : null}
					<Plant.ChartActualProductionIrradiation options={options} />
				</div>
			</AntCard>
		)
	}
}

function mapStateToProps(state) {
	return {
		common: state.common,
	}
}

export default connect(mapStateToProps, {})(CustomComponent);