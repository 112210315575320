import React, { Component } from 'react';
import { ToolboxControl } from "@layouts";
import { connect } from "react-redux";
import { AreaChartOutlined } from "@ant-design/icons";
import { AntCard, Loading } from "@layouts";
import * as Plant from "@features/Plant";

class CustomComponent extends Component {
    render() {
        const { common, meta, handleChangeTime, onChangeResizeChart } = this.props;

        const parentElementId = "plant-specific-yield";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

        // Init chart data
        const { loading, options } = meta.specificYield;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <AreaChartOutlined/> Specific Yield
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            selectTimeDefault="last_30_days"
                            selectTimeOptions={[
                                { title: "Last 7 days", value: "last_7_days" },
                                { title: "This week", value: "this_week" },
                                { title: "Last 30 days", value: "last_30_days" },
                                { title: "Last 6 months", value: "last_6_months" },
                                { title: "Last 12 months", value: "last_12_months" },
                                { title: "This year", value: "this_year" },
                            ]}
                            handleChangeTime={handleChangeTime}
                            onChangeResize={onChangeResizeChart}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-width">
                    {loading ? <Loading overwrite/> : null}
                    <Plant.ChartSpecificYield options={options}/>
                </div>
            </AntCard>
        )
    }
}

function mapStateToProps(state) {
    return {
        common: state.common,
        assetPerformance: state.assetPerformance,
    }
}

export default connect(mapStateToProps, {})(CustomComponent);