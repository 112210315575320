import React, { Component } from 'react';
import { connect } from "react-redux";
import { AntButton, AntCard, Loading, ToolboxControl } from "@layouts";
import { BarChartOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { withRouter } from "react-router-dom";
import { iconList } from "@features/Tools/Analysis/components/ListIcon/iconList";

class CustomComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchText: "",
		}
	}

	/**
	 * On change input
	 * @param e
	 */
	onChange = (e) => {
		const searchText = e.currentTarget.value;
		setTimeout(() => {
			this.setState({
				...this.state,
				searchText: searchText
			})
		})
	};

	/**
	 * Render search list
	 * @param searchList
	 * @returns {*[]}
	 */
	renderSearchList = (searchList) => {
		let { searchText } = this.state
		let newMatch = [];
		// Find in mockup
		searchList.forEach((group, groupIdx) => {
			let isDisplay = false;
			let features = [];

			// Render new list
			group.features.forEach((feature) => {
				if (feature.title.toString().toLowerCase().indexOf(searchText.toString().toLowerCase()) !== -1) {
					features.push({
						...feature
					})

					isDisplay = true;
				}
			})

			newMatch.push({
				"title": group.title,
				"features": features,
				"isDisplay": isDisplay,
				"no": groupIdx + 1,
			})
		})

		return newMatch
	}

	onClick = (e) => {
		const value = e.currentTarget.value;
		this.setState({
			...this.state,
			selectedItem: value
		}, this.props.onChangeDataType(value))
	};

	render() {
		const { common } = this.props;

		const parentElementId = "analysis-list";
		const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : '';

		const { searchText } = this.state;
		const { plantId, siteId } = this.props.match.params;
		const { analysis, parentId, currentDataType } = this.props

		let loading;
		let data = [];
		switch (true) {
			case plantId !== undefined:
				loading = analysis.listByPlant.loading;
				data = analysis.listByPlant.data;
				break;
			case siteId !== undefined:
				loading = analysis.listBySite.loading
				data = analysis.listBySite.data;
				break;
			default:
				break;
		}
		const searchList = this.renderSearchList(data);
		return (
			<AntCard
				id={parentElementId}
				className={"analysis-list card-custom " + fullScreenClass}
				title={
					<div className="card-custom-title">
						<div className="card-information">
							<BarChartOutlined/> Analysis features
						</div>
						<ToolboxControl parentElementId={parentElementId}/>
					</div>
				}
			>
				<div className="card-custom-body">
					<div>
						<div className="search-box">
							<Input
								className="search-input"
								placeholder="Search analysis"
								value={searchText}
								onChange={this.onChange}
								allowClear={true}
								style={{
									width: "100%",
									borderRadius: 3
								}}
							/>
						</div>
						<div className="search-group-result">
							{
								loading ? <Loading/> :
									(
										searchList.map((group, idx) => {
											return (
												(group.isDisplay) ? (
													<div className="group-result" key={idx}>
														<div className="group-title">
															{group.no + '. ' + group.title}
														</div>
														<div className="list-data">
															{
																group.features.map((item, idxItem) => {
																	return (
																		<ListItem
																			icon={iconList}
																			key={idxItem}
																			parentId={parentId}
																			item={item}
																			selectedItem={currentDataType}
																			onClick={this.onClick}
																		/>
																	)
																})
															}
														</div>
													</div>
												) : null
											)
										})
									)
							}
						</div>
					</div>
				</div>
			</AntCard>
		)
	}
}

const ListItem = ({ parentId, item, selectedItem, onClick, icon }) => {
	let classItem = 'data-item';

	let isFirstLoad = selectedItem === undefined;
	if ((selectedItem === item.dataType) || (isFirstLoad && item.selected)) {
		classItem += ' selected'
	}
	if (item.title === "I-V Curve") {
		classItem += ' custom-blocked';
	}
	return (
		<div className={classItem}>
			<AntButton
				type="link"
				value={`/analysis/${item.scope}/${parentId}/${item.dataType}`}
				onClick={onClick}
			>
				{icon[item.title]} {item.title}
			</AntButton>
		</div>
	)
}

function mapStateToProps(state) {
	return {
		common: state.common,
		analysis: state.analysis,
	}
}

export default withRouter(connect(mapStateToProps, {})(CustomComponent))