import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        height: 18,
        fontWeight: "bold",
        flexGrow: 1,
    },
    timeType: {
        width: "28%",
        textAlign: "left",
    },
    price: {
        width: "25%",
        textAlign: 'right',
    },
    total: {
        width: "23%",
        textAlign: "right",
    },
    cash: {
        width: '24%',
        textAlign: 'right',
    },
});

const TableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.timeType}>KHUNG GIỜ MUA ĐIỆN</Text>
        <Text style={styles.price}>ĐƠN GIÁ (đồng/kWh)</Text>
        <Text style={styles.total}>SẢN LƯỢNG (kWh)</Text>
        <Text style={styles.cash}>THÀNH TIỀN (đồng)</Text>
    </View>
);

export default TableHeader