import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import * as CONSTANTS from "@ultis/constants";
import { getPlantDetail } from "@features/Plant/redux";
import { resetStore } from "@features/Common/redux";
import { REPORT_FILE_TYPE, REPORT_TYPE, TIME_PERIOD } from "@ultis/constants";
import moment from "moment";
import { reportGetDetail, reportGetList, reportRequestDownload } from "@features/Tools/Report/redux";
import { message } from "antd";

let defaultTimeRanges = {}
defaultTimeRanges[REPORT_TYPE.PLANT_REPORT] = {}
defaultTimeRanges[REPORT_TYPE.PLANT_REPORT][TIME_PERIOD.MONTHLY] = [
    moment().subtract(30, 'days').startOf('date'),
    moment().endOf('date')
]
defaultTimeRanges[REPORT_TYPE.PLANT_REPORT][TIME_PERIOD.YEARLY] = [
    moment().subtract(12, "months").startOf('month'),
    moment().endOf('month')
]
defaultTimeRanges[REPORT_TYPE.DEVICE_REPORT] = {}
defaultTimeRanges[REPORT_TYPE.DEVICE_REPORT][TIME_PERIOD.WEEK] = [
    moment().subtract(7, 'days').startOf('date'),
    moment().endOf('date')
]
// DETAIL DEVICE REPORT
defaultTimeRanges[REPORT_TYPE.DEVICE_DETAIL_REPORT] = {}
defaultTimeRanges[REPORT_TYPE.DEVICE_DETAIL_REPORT][TIME_PERIOD.WEEK] = [
    moment().subtract(7, 'days').startOf('date'),
    moment().endOf('date')
]

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reportId: null,
            reportType: REPORT_TYPE.PLANT_REPORT,
            downloadRequest: {
                timePeriod: TIME_PERIOD.MONTHLY,
                fileType: REPORT_FILE_TYPE.PDF,
                timeRanges: [
                    moment().subtract(30, 'days').startOf('date'),
                    moment().endOf('date')
                ],
            }
        }
    }

    /**
     * On change report type
     * @param reportId
     * @param reportType
     */
    onChangeReportType = (reportId, reportType) => {
        let timePeriod = reportType === REPORT_TYPE.PLANT_REPORT ? TIME_PERIOD.MONTHLY : TIME_PERIOD.WEEK;
        let timeRanges = defaultTimeRanges[reportType][timePeriod];
        this.setState({
            ...this.state,
            reportId: reportId,
            reportType: reportType,
            downloadRequest: {
                ...this.state.downloadRequest,
                fileType: reportType === REPORT_TYPE.DEVICE_DETAIL_REPORT ? REPORT_FILE_TYPE.EXCEL : REPORT_FILE_TYPE.PDF,
                timePeriod: timePeriod,
                timeRanges: timeRanges,
            }
        })

        // Dispatch get detail report
        this.props.reportGetDetail(reportId)
    }

    /**
     * On change time period
     * @param value
     */
    onChangeTimePeriod = (value) => {
        let timeRanges = defaultTimeRanges[this.state.reportType][value]
        this.setState({
            ...this.state,
            downloadRequest: {
                ...this.state.downloadRequest,
                timePeriod: value,
                timeRanges: timeRanges,
            }
        })
    }

    /**
     * On change file type
     * @param value
     */
    onChangeFileType = (value) => {
        this.setState({
            ...this.state,
            downloadRequest: {
                ...this.state.downloadRequest,
                fileType: value
            }
        })
    }

    /**
     * On change time ranges
     * @param value
     */
    onChangeTimeRanges = (value) => {
        this.setState({
            ...this.state,
            downloadRequest: {
                ...this.state.downloadRequest,
                timeRanges: value
            }
        })
    }

    /**
     * On click download button
     */
    onClickDownload = () => {
        let { reportId, downloadRequest } = this.state
        const { plantId } = this.props.match.params;

        let from = downloadRequest.timeRanges[0];
        let to = downloadRequest.timeRanges[1];
        let res, isValidate, messageError = "Range report must be in ";

        switch (downloadRequest.timePeriod) {
            // case month
            case 'M':
                res = to.diff(from, 'month');
                isValidate = res <= 1; // is in 1 month
                messageError += "1 month";
                break;
            case 'Y':
                res = to.diff(from, 'year');
                isValidate = res <= 1; // is in 1 year
                messageError += "1 year";
                break;
            case 'W':
                res = to.diff(from, 'week');
                isValidate = res <= 1; // is in 1 week (7 days)
                messageError += "1 week";
                break;
            default:
                res = null;
                isValidate = false;
                break;
        }

        if (!isValidate) {
            return message.open({
                type: 'error',
                content: messageError,
                duration: 3,
            });
        }

        let params = {
            plantId: plantId ?? null,
            from: from ? from.format(CONSTANTS.DEFAULT_FORMAT_DATETIME) : '',
            to: to ? to.format(CONSTANTS.DEFAULT_FORMAT_DATETIME) : to,
            groupBy: downloadRequest.timePeriod === TIME_PERIOD.MONTHLY ? 'date' : 'month',
            reportType: downloadRequest.fileType ?? null,
        }
        this.props.reportRequestDownload(reportId, params)
    }

    render() {
        const { isFound } = this.props.plant.detail

        if (!isFound) {
            return (
                <Redirect to="/errors/404"/>
            )
        }

        return (
            <President
                {...this.state}
                {...this.props}
                onClickDownload={this.onClickDownload}
                onChangeReportType={this.onChangeReportType}
                onChangeTimePeriod={this.onChangeTimePeriod}
                onChangeFileType={this.onChangeFileType}
                onChangeTimeRanges={this.onChangeTimeRanges}
            />
        )
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        const preParams = prevProps.match.params;
        const currentParams = this.props.match.params;

        if (preParams.plantId !== currentParams.plantId) {
            this.fetchData();
        }
    }

    componentWillUnmount() {
        this.props.resetStore()
    }

    fetchData = () => {
        const { plantId } = this.props.match.params;

        // Fetch data
        this.props.getPlantDetail(plantId)

        // Get report list
        this.props.reportGetList(plantId)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        resetStore: () => {
            dispatch(resetStore());
        },
        getPlantDetail: (plantId) => {
            dispatch(getPlantDetail(plantId));
        },

        reportGetList: (plantId) => {
            dispatch(reportGetList(plantId));
        },
        reportGetDetail: (reportId) => {
            dispatch(reportGetDetail(reportId));
        },
        reportRequestDownload: (reportType, params) => {
            dispatch(reportRequestDownload(reportType, params));
        },
    };
}


function mapStateToProps(state) {
    return {
        plant: state.plant,
        report: state.report,
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container))