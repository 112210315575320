import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { TIMEZONE, tooltipDateFormat } from "../index";
import { Select } from "antd";

class CustomComponent extends Component {
    render() {
        const { options } = this.props

        // Init chart data
        const { series, groupBy } = options;

        // First line
        const seriesFirst = series.length > 0 ? series[0] : {}
        const dataFirst = seriesFirst.data ?? []
        const nameFirst = seriesFirst.name ?? ''
        const typeFirst = seriesFirst.type ?? ''
        const unitFirst = seriesFirst.unit ?? ''

        return (
            <>
                <div className="input-group">
                    <Select
                        className="input-group-item"
                        defaultValue={devices[0].id}
                        listHeight={400}
                        style={{
                            width: "calc(50% - 6px)",
                        }}
                        //onChange={handleChangeTime}
                    >
                        {
                            devices.map((item, key) => {
                                return <Select.Option key={key} value={item.id}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                    <Select
                        className="input-group-item"
                        defaultValue={strings[0].id}
                        listHeight={400}
                        style={{
                            width: "calc(50% - 6px)",
                        }}
                        //onChange={handleChangeTime}
                    >
                        {
                            strings.map((item, key) => {
                                return <Select.Option key={key} value={item.id}>{item.name}</Select.Option>
                            })
                        }
                    </Select>
                </div>
                <HighchartsReact
                    containerProps={{ className: "highcharts-react" }}
                    highcharts={Highcharts}
                    options={{
                        "chart": {
                            "zoomType": "xy"
                        },
                        "title": {
                            "text": "",
                        },
                        "xAxis": [
                            {
                                //"type"  : "datetime",
                                //"labels": renderLabel(groupBy),
                            }
                        ],
                        "yAxis": [
                            // First line
                            {
                                "min": 0,
                                "labels": {
                                    "format": `{value} ${unitFirst}`,
                                    "style": {
                                        "color": "#666666"
                                    }
                                },
                                "title": {
                                    "text": "",
                                },
                            },
                        ],
                        "tooltip": {
                            "xDateFormat": tooltipDateFormat(groupBy, TIMEZONE),
                            "shared": true
                        },
                        "legend": {},
                        "series": [
                            {
                                "marker": {
                                    "enabled": false
                                },
                                "dashStyle": "Solid",
                                "zIndex": 4,
                                "lineWidth": 2,
                                "states": {
                                    "hover": {
                                        "enabled": true,
                                        "lineWidth": 2
                                    }
                                },
                                "type": typeFirst,
                                "name": nameFirst,
                                "data": dataFirst,
                            }
                        ],
                        "plotOptions": {
                            "series": {
                                "animation": false,
                                "maxPointWidth": 100
                            },
                        }
                    }}
                />
            </>
        )
    }
}

export default CustomComponent

const strings = [
    {
        "id": 1,
        "name": "STR#1",
    },
    {
        "id": 2,
        "name": "STR#2",
    },
    {
        "id": 3,
        "name": "STR#3",
    },
    {
        "id": 4,
        "name": "STR#4",
    },
    {
        "id": 5,
        "name": "STR#5",
    },
    {
        "id": 6,
        "name": "STR#6",
    },
    {
        "id": 7,
        "name": "STR#7",
    },
    {
        "id": 8,
        "name": "STR#8",
    },
    {
        "id": 9,
        "name": "STR#9",
    },
    {
        "id": 10,
        "name": "STR#10",
    },
    {
        "id": 11,
        "name": "STR#11",
    },
    {
        "id": 12,
        "name": "STR#12",
    },
]

const devices = [
    {
        "id": 1,
        "name": "SUN2000-1",
    },
    {
        "id": 2,
        "name": "SUN2000-2",
    },
    {
        "id": 3,
        "name": "SUN2000-3",
    },
    {
        "id": 4,
        "name": "SUN2000-4",
    },
    {
        "id": 5,
        "name": "SUN2000-5",
    },
    {
        "id": 6,
        "name": "SUN2000-6",
    },
    {
        "id": 7,
        "name": "SUN2000-7",
    },
    {
        "id": 8,
        "name": "SUN2000-8",
    },
    {
        "id": 9,
        "name": "SUN2000-9",
    },
    {
        "id": 10,
        "name": "SUN2000-10",
    },
]