import React, { Component } from 'react';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from "highcharts/modules/heatmap";
import highcharts from "highcharts/highstock";

HighchartsHeatmap(highcharts);

class CustomComponent extends Component {
    render() {
        const { options, max } = this.props

        // Init chart data
        const { series, xAxis, yAxis, unixLegend } = options;

        // ["String", "Device Name", "Value"]
        let processSeries = !!series && series[0] ? series[0] : [];
        return (
            <HighchartsReact
                containerProps={{ className: "highcharts-react heatmap" }}
                highcharts={highcharts}
                options={{
                    "chart": {
                        "zoomType": "xy",
                        "type": 'heatmap',
                        "plotBorderWidth": 1,
                        //"marginTop"      : 40,
                        //"marginBottom"   : 80,
                        "zooming": {
                            "mouseWheel": {
                                "enabled": true,
                                "sensitivity": 1.1,
                                "type": 'x',
                            },
                        },
                    },
                    "title": {
                        "text": "",
                    },
                    "xAxis": {
                        "categories": xAxis.categories ?? [],
                    },

                    "yAxis": {
                        "categories": yAxis.categories ?? [],
                        "title": null,
                        reversed: true,
                    },

                    "colorAxis": {
                        "stops": [
                            // OLD PROFILE
                            // [0, "#FFFBF9"],
                            // [0.2, "#FEF3EA"],
                            // [0.4, "#FBD6B6"],
                            // [0.6, "#FBCBA4"],
                            // [0.8, "#FBC69C"],
                            // [0.9, "#FAC496"],
                            // [1, "#F9B982"]

                            // PREV PROFILE
                            // [0, "#FF00FF"],
                            // [0.2, "#0000FF"],
                            // [0.4, "#00BFFF"],
                            // [0.6, "#00FF00"],
                            // [0.8, "#FEC20C"],
                            // [0.9, "#FF7F00"],
                            // [1, "#FF0000"],

                            // LAST PROFILE
                            [0, "#D3D3D3"],
                            [0.01, "#808080"],
                            [0.2, "#00BFFF"],
                            [0.3, "#00FFFF"],
                            [0.6, "#00FF00"],
                            [0.8, "#FEC20C"],
                            [0.9, "#FF7F00"],
                            [1, "#FF0000"],
                        ],
                        "min": 0,
                        "max": max,
                        "startOnTick": false,
                        "endOnTick": false,
                        "labels": {
                            "format": `{value}${unixLegend}`
                        },
                        reversed: false
                    },

                    "tooltip": {
                        /* formatter: function () {
                             return '<b>' + getPointCategoryName(this.point, 'x') + '</b> of <br><b>' +
                                 this.point.value + '</b> (A) <br><b>' + getPointCategoryName(this.point, 'y') + '</b>';
                         }*/
                        formatter: function () {
                            return `<b>${getPointCategoryName(this.point, 'x')}</b>`
                                + ` of<br>`
                                + `<b>${getPointCategoryName(this.point, 'y')}</b><br>`
                                + `<b>${this.point.value}</b>(${unixLegend})`;
                        }
                    },
                    "legend": {
                        "align": 'right',
                        "layout": 'vertical',
                        "verticalAlign": 'top',
                        "y": -5,
                        "x": 0,
                        "symbolHeight": 352,
                    },
                    "series": [{
                        "borderWidth": 1,
                        //"data"       : data,
                        "data": processSeries.data ?? [],
                        // "dataLabels": {
                        //     "enabled": true,
                        //     formatter: function() {
                        //         let labelRender = null;
                        //         console.log(this.point.value);
                        //         if (this.point.value === 0) {
                        //             labelRender = "n/a";
                        //         }
                        //         return labelRender;
                        //     }
                        // },
                    }],
                    "plotOptions": {
                        "series": {
                            "animation": false,
                            "maxPointWidth": 100
                        },
                    },
                }}
            />
        )
    }
}

export default CustomComponent

function getPointCategoryName(point, dimension) {
    let series = point.series,
        isY = dimension === 'y',
        axis = series[isY ? 'yAxis' : 'xAxis'];
    return axis.categories[point[isY ? 'y' : 'x']];
}