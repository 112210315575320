import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import { getPlantData, getPlantDetail, } from "@features/Plant/redux";
import * as CONSTANTS from "@ultis/constants";
import helpers from "@ultis/helpers";
import { DATA_TYPE } from "@ultis/constants";

class Container extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCollapsed: true,
			specificYield: {
				dataType: DATA_TYPE.SPECIFIC_YIELD,
				timeRange: CONSTANTS.FILTER_LAST_30_DAYS
			},
			dailyIODiagram: {
				dataType: DATA_TYPE.DAILY_IO_DIAGRAM,
				timeRange: CONSTANTS.FILTER_LAST_30_DAYS
			},
			performanceRatioTracking: {
				dataType: DATA_TYPE.PERFORMANCE_RATIO_TRACKING,
				timeRange: CONSTANTS.FILTER_LAST_7_DAYS
			},
			actualProductionVsBudgetProduction: {
				dataType: DATA_TYPE.ACTUAL_PRODUCTION_VS_BUDGET_PRODUCTION,
				timeRange: CONSTANTS.FILTER_LAST_7_DAYS
			},
			carbonOffset: {
				dataType: DATA_TYPE.CARBON_OFFSET,
				timeRange: CONSTANTS.FILTER_LAST_7_DAYS
			},
			inverterPerformance: {
				dataType: DATA_TYPE.INVERTER_PERFORMANCE,
			},
			gaugesPerformance: {
				dataType: DATA_TYPE.GAUGES_PERFORMANCE,
			}
		}
	}

	onCollapsed = (value) => {
		this.setState({
			...this.state,
			isCollapsed: value
		})
	}

	handleChangeSpecificYield = (value) => {
		const { match: { params: { plantId } } } = this.props;
		const { specificYield } = this.state;
		let range = helpers.getTimeRangeByFilterTime(value);

		this.setState({
			...this.state,
			specificYield: {
				...this.state.specificYield,
				timeRange: value
			}
		}, this.props.getPlantData({
			plantId: plantId,
			groupBy: range.groupBy,
			from: range.fromString,
			to: range.toString,
			silent: false,
			dataType: specificYield.dataType,
		}));
	}

	handleChangeDailyIODiagram = (value) => {
		const { match: { params: { plantId } } } = this.props;
		const { dailyIODiagram } = this.state;
		let range = helpers.getTimeRangeByFilterTime(value);

		this.setState({
			...this.state,
			dailyIODiagram: {
				...this.state.dailyIODiagram,
				timeRange: value
			}
		}, this.props.getPlantData({
			plantId: plantId,
			groupBy: range.groupBy,
			from: range.fromString,
			to: range.toString,
			silent: false,
			dataType: dailyIODiagram.dataType,
		}))
	}

	handleChangeActualProductionVsBudgetProduction = (value) => {
		const { match: { params: { plantId } } } = this.props;
		const { actualProductionVsBudgetProduction } = this.state;
		let range = helpers.getTimeRangeByFilterTime(value)

		this.setState({
			...this.state,
			actualProductionVsBudgetProduction: {
				...this.state.actualProductionVsBudgetProduction,
				timeRange: value
			}
		}, this.props.getPlantData({
			plantId: plantId,
			groupBy: range.groupBy,
			from: range.fromString,
			to: range.toString,
			silent: false,
			dataType: actualProductionVsBudgetProduction.dataType,
		}))
	}

	handleChangeCarbonOffset = (value) => {
		const { match: { params: { plantId } } } = this.props;
		const { carbonOffset } = this.state;
		let range = helpers.getTimeRangeByFilterTime(value)

		this.setState({
			...this.state,
			carbonOffset: {
				...this.state.carbonOffset,
				timeRange: value
			}
		}, this.props.getPlantData({
			plantId: plantId,
			groupBy: range.groupBy,
			from: range.fromString,
			to: range.toString,
			silent: false,
			dataType: carbonOffset.dataType,
		}))
	}

	handleChangePerformanceRatioTracking = (value) => {
		const { match: { params: { plantId } } } = this.props;
		const { performanceRatioTracking } = this.state;
		let range = helpers.getTimeRangeByFilterTime(value);

		this.setState({
			...this.state,
			performanceRatioTracking: {
				...this.state.performanceRatioTracking,
				timeRange: value
			}
		}, this.props.getPlantData({
			plantId: plantId,
			groupBy: range.groupBy,
			from: range.fromString,
			to: range.toString,
			silent: false,
			dataType: performanceRatioTracking.dataType,
		}))
	}

	render() {
		const { plant: { detail, meta } } = this.props;

		const { isCollapsed } = this.state;

		if (!detail.isFound) {
			return (
				<Redirect to="/errors/404"/>
			)
		}
		return (
			<President
				detail={detail}
				meta={meta}
				isCollapsed={isCollapsed}
				onCollapsed={this.onCollapsed}
				handleChangeSpecificYield={this.handleChangeSpecificYield}
				handleChangeDailyIODiagram={this.handleChangeDailyIODiagram}
				handleChangePerformanceRatioTracking={this.handleChangePerformanceRatioTracking}
				handleChangeActualProductionVsBudgetProduction={this.handleChangeActualProductionVsBudgetProduction}
				handleChangeCarbonOffset={this.handleChangeCarbonOffset}
			/>
		)
	}

	componentDidMount() {
		this.fetchData();
		this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, () => this.fetchData())
	}

	componentDidUpdate(prevProps) {
		const preParams = prevProps.match.params;
		const currentParams = this.props.match.params;

		if ((preParams.plantId !== currentParams.plantId)) {
			this.fetchData();
		}
	}

	fetchData = () => {
		const { plantId } = this.props.match.params;

		const {
			specificYield,
			dailyIODiagram,
			performanceRatioTracking,
			inverterPerformance,
			gaugesPerformance,
			actualProductionVsBudgetProduction,
			carbonOffset,
		} = this.state;

		// Fetch data
		this.props.getPlantDetail(plantId);

		let specificYieldRange = helpers.getTimeRangeByFilterTime(specificYield.timeRange)
		let dailyIODiagramRange = helpers.getTimeRangeByFilterTime(dailyIODiagram.timeRange)
		let performanceRatioTrackingRange = helpers.getTimeRangeByFilterTime(performanceRatioTracking.timeRange)
		let actualProductionVsBudgetProductionRange = helpers.getTimeRangeByFilterTime(performanceRatioTracking.timeRange)
		let carbonOffsetRange = helpers.getTimeRangeByFilterTime(performanceRatioTracking.timeRange)

		this.props.getPlantData({
			plantId: plantId,
			groupBy: specificYieldRange.groupBy,
			from: specificYieldRange.fromString,
			to: specificYieldRange.toString,
			silent: false,
			dataType: specificYield.dataType,
		})

		this.props.getPlantData({
			plantId: plantId,
			silent: false,
			dataType: gaugesPerformance.dataType,
		})

		this.props.getPlantData({
			plantId: plantId,
			groupBy: actualProductionVsBudgetProductionRange.groupBy,
			from: actualProductionVsBudgetProductionRange.fromString,
			to: actualProductionVsBudgetProductionRange.toString,
			silent: false,
			dataType: actualProductionVsBudgetProduction.dataType,
		})

		this.props.getPlantData({
			plantId: plantId,
			groupBy: carbonOffsetRange.groupBy,
			from: carbonOffsetRange.fromString,
			to: carbonOffsetRange.toString,
			silent: false,
			dataType: carbonOffset.dataType,
		})

		this.props.getPlantData({
			plantId: plantId,
			groupBy: performanceRatioTrackingRange.groupBy,
			from: performanceRatioTrackingRange.fromString,
			to: performanceRatioTrackingRange.toString,
			silent: false,
			dataType: performanceRatioTracking.dataType,
		})

		this.props.getPlantData({
			plantId: plantId,
			silent: false,
			dataType: inverterPerformance.dataType,
		})

		this.props.getPlantData({
			plantId: plantId,
			groupBy: dailyIODiagramRange.groupBy,
			from: dailyIODiagramRange.fromString,
			to: dailyIODiagramRange.toString,
			silent: false,
			dataType: dailyIODiagram.dataType,
		})
	}
}

function mapDispatchToProps(dispatch) {
	return {
		getPlantDetail: (plantId) => {
			dispatch(getPlantDetail(plantId));
		},

		getPlantData: (params) => {
			dispatch(getPlantData(params));
		},
	};
}


function mapStateToProps(state) {
	return {
		socketIO: state.socketIO,
		common: state.common,
		plant: state.plant,
		assetPerformance: state.assetPerformance,
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container));