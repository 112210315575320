import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import TableFooter from './TableFooter'

const styles = StyleSheet.create({
	tableContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
	},
});

const ItemsTable = ({ tableData, tableFooter }) => (
	<View style={styles.tableContainer}>
		<TableHeader/>
		<TableRow items={tableData}/>
		<TableFooter data={tableFooter}/>
	</View>
);

export default ItemsTable