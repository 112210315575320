import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { Redirect, withRouter } from "react-router-dom";
import { getPlantData, getPlantDetail } from "@features/Plant/redux";
import { resetStore } from "@features/Common/redux";
import * as CONSTANTS from "@ultis/constants";
import helpers from "@ultis/helpers";
import { fetchTableEvent } from "@features/Event/redux";

class Container extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: true,
            actualProduction: {
                dataType: CONSTANTS.DATA_TYPE.ACTUAL_PRODUCTION_AND_IRRADIATION,
                timeRange: CONSTANTS.FILTER_LAST_24_HOUR
            },
            stringMonitoring: {
                dataType: CONSTANTS.DATA_TYPE.STRING_MONITORING,
            },
            IVCurve: {
                dataType: CONSTANTS.DATA_TYPE.IV_CURVE,
                deviceNumber: null,
                stringNumber: null,
            },
            inverterMonitoring: {
                dataType: CONSTANTS.DATA_TYPE.INVERTER_MONITORING,
            },
            gaugesMonitoring: {
                dataType: CONSTANTS.DATA_TYPE.GAUGES_MONITORING,
            },
            inverterDetail: {
                dataType: CONSTANTS.DATA_TYPE.INVERTER_DETAIL,
            },
            defaultDeviceCode: null,
            defaultTypeString: "PVampe",
        }
    }

    onCollapsed = (value) => {
        this.setState({
            ...this.state,
            isCollapsed: value
        })
    }

    handleChangeTimeActual = (value) => {
        const { plantId } = this.props.match.params;
        const { actualProduction } = this.state;
        let range = helpers.getTimeRangeByFilterTime(value)

        this.setState({
            ...this.state,
            actualProduction: {
                ...this.state.actualProduction,
                timeRange: value
            }
        }, this.props.getPlantData({
            plantId: plantId,
            groupBy: range.groupBy,
            from: range.fromString,
            to: range.toString,
            silent: false,
            dataType: actualProduction.dataType,
        }))
    }

    handleChangeTimeActualBudget = (value) => {
        const { plantId } = this.props.match.params;
        let range = helpers.getTimeRangeByFilterTime(value)
        this.props.getPlantData({
            plantId: plantId,
            groupBy: range.groupBy,
            from: range.fromString,
            to: range.toString,
            silent: false,
            dataType: CONSTANTS.DATA_TYPE.ACTUAL_PR_AND_BUDGET_PR,
        })
    }

    handleChangeTimeInverterPerformance = (value) => {
        const { plantId } = this.props.match.params;
        let range = helpers.getTimeRangeByFilterTime(value)
        this.props.getPlantData({
            plantId: plantId,
            groupBy: range.groupBy,
            from: range.fromString,
            to: range.toString,
            silent: false,
            dataType: CONSTANTS.DATA_TYPE.INVERTER_PERFORMANCE,
        })
    }

    handleChangeInverter = value => {
        const { plantId } = this.props.match.params;
        setTimeout(() => {
            this.setState({
                ...this.state,
                defaultDeviceCode: value,
            })

            this.props.getPlantData({
                plantId: plantId,
                deviceCode: this.state.defaultDeviceCode,
                silent: false,
                dataType: CONSTANTS.DATA_TYPE.INVERTER_DETAIL,
            })
        });
    }

    handleChangeTypeString = value => {
        const { plantId } = this.props.match.params;
        setTimeout(() => {
            this.setState({
                ...this.state,
                defaultTypeString: value,
            })
            this.props.getPlantData({
                plantId: plantId,
                silent: false,
                dataType: CONSTANTS.DATA_TYPE.STRING_MONITORING,
                param: this.state.defaultTypeString,
            })
        })
    }

    onChangeResizeChart = () => {
        console.log('onChangeResizeChart')
    }

    render() {
        const {
            isFound,
        } = this.props.plant.detail

        const { isCollapsed, defaultDeviceCode, defaultTypeString } = this.state

        if (!isFound) {
            return (
                <Redirect to="/errors/404"/>
            )
        }
        return (
            <President
                {...this.props}
                isCollapsed={isCollapsed}
                onCollapsed={this.onCollapsed}
                onChangeResizeChart={this.onChangeResizeChart}
                handleChangeTimeActual={this.handleChangeTimeActual}
                handleChangeTimeInverterPerformance={this.handleChangeTimeInverterPerformance}
                handleChangeTimeActualBudget={this.handleChangeTimeActualBudget}
                handleChangeInverter={this.handleChangeInverter}
                defaultDeviceCode={defaultDeviceCode}
                handleChangeTypeString={this.handleChangeTypeString}
                defaultTypeString={defaultTypeString}
            />
        )
    }

    componentDidMount() {
        this.fetchData();
        this.fetchRealTimeData();
        this.props.socketIO.socket.on(CONSTANTS.SOCKET_EMIT.AUTO_REFRESH_DATA, (data) => {
            this.fetchData()
            this.fetchRealTimeData();
        })
    }

    componentDidUpdate(prevProps) {
        const preParams = prevProps.match.params;
        const currentParams = this.props.match.params;

        const prevDetail = prevProps.plant.detail;
        const currentDetail = this.props.plant.detail;

        if ((preParams.plantId !== currentParams.plantId)) {
            this.fetchData();
        }
        if ((prevDetail.id !== currentDetail.id)) {
            this.fetchRealTimeData();
        }
    }

    componentWillUnmount() {
        this.props.resetStore()
    }

    fetchData = () => {
        const { plantId, siteId } = this.props.match.params;

        const {
            actualProduction,
            inverterMonitoring,
            gaugesMonitoring,
            stringMonitoring,
            IVCurve,
            defaultTypeString,
        } = this.state;

        // Fetch data
        this.props.getPlantDetail(plantId)

        let range = helpers.getTimeRangeByFilterTime(actualProduction.timeRange)
        this.props.getPlantData({
            plantId: plantId,
            groupBy: range.groupBy,
            from: range.fromString,
            to: range.toString,
            silent: false,
            dataType: actualProduction.dataType,
        })
        this.props.getPlantData({
            plantId: plantId,
            silent: false,
            dataType: inverterMonitoring.dataType,
        })
        this.props.getPlantData({
            plantId: plantId,
            silent: false,
            dataType: gaugesMonitoring.dataType,
        })

        this.props.getPlantData({
            plantId: plantId,
            silent: false,
            dataType: stringMonitoring.dataType,
            param: defaultTypeString,
        })

        this.props.getPlantData({
            plantId: plantId,
            silent: false,
            dataType: IVCurve.dataType,
        })

        this.props.fetchTableEvent({
            plantId: plantId,
            siteId: siteId,
        })
    }

    fetchRealTimeData = () => {
        const { detail } = this.props.plant;
        const { inverterDetail } = this.state;
        const plantId = this.props.plant.detail.id;

        if (detail.data.inverters && detail.data.inverters[0] !== undefined) {
            setTimeout(() => {
                this.setState({
                    ...this.state,
                    defaultDeviceCode: detail.data.inverters[0].code,
                })
                this.props.getPlantData({
                    plantId: plantId,
                    deviceCode: this.state.defaultDeviceCode,
                    silent: false,
                    dataType: inverterDetail.dataType,
                })
            })
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        resetStore: () => {
            dispatch(resetStore());
        },

        getPlantDetail: (plantId) => {
            dispatch(getPlantDetail(plantId));
        },

        getPlantData: (params) => {
            dispatch(getPlantData(params));
        },
        fetchTableEvent: (params) => {
            dispatch(fetchTableEvent(params));
        },
    };
}


function mapStateToProps(state) {
    return {
        socketIO: state.socketIO,
        common: state.common,
        plant: state.plant,
        technicalMonitoring: state.technicalMonitoring,
        auth: state.auth
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Container))