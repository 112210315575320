import React, { Component } from 'react';
import President from './President';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom"
import { login } from "@features/Auth/redux/actions";

class Container extends Component {
	handleLogin = (data) => {
		setTimeout(() => {
			this.props.login({
				email: data.email !== undefined ? data.email : '',
				password: data.password !== undefined ? data.password : '',
			})
		}, 1000);
	}

	render() {
		const { user, configs, login } = this.props.auth;

		if (user.id !== null && user.id !== undefined) {
			return (
				<Redirect to={configs.homepage}/>
			)
		}

		return (
			<President
				crud={this.props.crud}
				loading={login.loading}
				handleLogin={this.handleLogin}
			/>
		)
	}
}

function mapDispatchToProps(dispatch) {
	return {
		login: (data) => {
			dispatch(login(data));
		},
	};
}

function mapStateToProps(state) {
	return {
		auth: state.auth,
		crud: state.crud
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Container)