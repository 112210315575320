import * as CONSTANTS from "./constants";
import { apiGet } from "@common/crud";

export function fetchPlants(siteId) {
    return dispatch => {
        dispatch(fetchPlantsLoadingAction())
        dispatch(apiGet('overview/plants', {
                "siteId": siteId ?? null,
                "type": "overview",
            }, {}, fetchPlantsAction)
        )
    }
}

function fetchPlantsLoadingAction() {
    return {
        type: CONSTANTS.FETCH_SITES_OVERVIEW_LOADING_ACTION,
        payload: null
    };
}

function fetchPlantsAction(response) {
    return {
        type: CONSTANTS.FETCH_SITES_OVERVIEW_ACTION,
        payload: response.data
    };
}

export function findLocation(coordinatesList, zoom) {
    return dispatch => {
        dispatch(findLocationAction(coordinatesList, zoom))
    }
}

function findLocationAction(coordinatesList, zoom) {
    return {
        type: CONSTANTS.FIND_LOCATION_ACTION,
        payload: {
            coordinatesList,
            zoom: zoom,
        }
    };
}