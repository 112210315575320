import React, { Component } from 'react';
import { connect } from "react-redux";

import { AreaChartOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { AntCard, ToolboxControl, TagInverterStatus, AntButton } from "@layouts";
import { Progress, Table } from "antd";
import helpers from "@ultis/helpers";

class CustomComponent extends Component {
    columns = () => {
        const { onClickViewInverter } = this.props;
        return [
            {
                ellipsis: true,
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (value) => <TagInverterStatus level={value}/>,
                align: "center",
                width: 80,
            },
            {
                ellipsis: true,
                title: 'Inverters',
                dataIndex: 'name',
                key: 'name',
                //sorter   : (a, b) => a.name - b.name,
            },
            {
                ellipsis: true,
                title: 'Power (kW)',
                dataIndex: 'currentPower',
                key: 'currentPower',
                render: (value, item) =>
                    <div className="col-power">
                        <span className="text-value">{value}</span>
                        <Progress
                            percent={helpers.percentage(item.currentPower, item.continuousPower)}
                            showInfo={false}
                        />
                    </div>,
                align: "center",
                width: 200,
            },
            {
                ellipsis: true,
                title: 'Today Yield (kWh)',
                dataIndex: 'todayProduction',
                key: 'todayProduction',
                align: "center",
                width: 120,
            },
            {
                ellipsis: true,
                title: 'Total Yield (MWh)',
                dataIndex: 'totalProduction',
                key: 'totalProduction',
                align: "center",
                width: 120,
            },
            {
                ellipsis: true,
                title: 'PR (%)',
                dataIndex: 'performanceRatio',
                key: 'performanceRatio',
                align: "center",
                width: 80
            },
            /*  {
                  ellipsis : true,
                  title    : 'PLF (%)',
                  dataIndex: 'plantLoadFactor',
                  key      : 'plantLoadFactor',
                  align    : "center",
                  width    : 80
              },*/
            /* {
                 ellipsis : true,
                 title    : 'CUF (%)',
                 dataIndex: 'capacityUtilisationFactor',
                 key      : 'capacityUtilisationFactor',
                 align    : "center",
                 width    : 80
             },*/
            {
                ellipsis: true,
                title: 'Energy Yield (kWh/kWp)',
                dataIndex: 'energyYield',
                key: 'energyYield',
                align: "center",
                width: 160
            },
            {
                ellipsis: true,
                title: 'Temperature (°C)',
                dataIndex: 'temperature',
                key: 'temperature',
                align: "center",
                width: 120
            },
            // action onClick => not use in this time
            // {
            //     ellipsis: true,
            //     title: "Action",
            //     dataIndex: 'action',
            //     key: 'action',
            //     align: "center",
            //     width: 120,
            //     render: (value, item) => <div className="group-button">
            //         <AntButton
            //             size="small"
            //             icon={<EditOutlined/>}
            //             type="primary" ghost
            //             value={item.id}
            //             onClick={onClickViewInverter}
            //         >
            //         </AntButton>
            //     </div>
            // },
        ];
    }

    render() {
        const { common, meta, defaultTypeString, handleChangeTypeString } = this.props
        const parentElementId = "plant-InverterMonitoring";
        const fullScreenClass = parentElementId === common.fullScreen.elementId ? common.fullScreen.class : ''

        // Data from inverter
        const {
            loading,
            total,
            totalPower,
            unit,
            lastTime,
            inverters
        } = meta.inverterMonitoring;

        return (
            <AntCard
                id={parentElementId}
                className={"card-custom " + fullScreenClass}
                title={
                    <div className="card-custom-title">
                        <div className="card-information">
                            <AreaChartOutlined/> Inverter monitoring
                            <span> </span>
                            <i>- {total} Inverters - {totalPower} {unit}</i>
                        </div>
                        <ToolboxControl
                            parentElementId={parentElementId}
                            //showSetting={true}
                            updateAt={lastTime}
                        />
                    </div>
                }
            >
                <div className="card-custom-body full-width full-height">
                    <Table
                        columns={this.columns()}
                        dataSource={inverters}
                        pagination={false}
                        loading={loading}
                        rowKey={"id"}
                        scroll={{ x: 1000 }}
                    >
                    </Table>
                </div>
            </AntCard>
        )
    }

}

function mapStateToProps(state) {
    return {
        common: state.common,
        plant: state.plant,
    }
}

export default connect(mapStateToProps, {})(CustomComponent)

